import { graphql } from "gatsby"
import React from "react"
import {
  Section,
  SectionProps,
} from "~/components/FlexibleLayouts/elements/Section"
import VideoModal from "~/components/VideoModal"

export interface FeaturedVideoProps {
  section: SectionProps
  previewImage: any
  videoEmbed: string
}

export const FeaturedVideo = ({
  section,
  previewImage,
  videoEmbed,
}: FeaturedVideoProps) => (
  <Section {...section}>
    <VideoModal
      previewImage={previewImage}
      videoEmbed={videoEmbed}
      playBtnClass="bg-beige "
      textClass="text-black"
      iconClass="h-24 md:h-auto"
    />
  </Section>
)

export const layoutQueryFragment = graphql`
  fragment FeaturedVideo on WpPage_Page_FlexibleContent {
    ... on WpPage_Page_FlexibleContent_FeaturedVideo {
      fieldGroupName
      previewImage {
        ...Image
      }
      videoEmbed
      ...sectionFeaturedVideo
    }
  }
`
