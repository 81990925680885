import { graphql } from "gatsby"
import React from "react"
import {
  Section,
  SectionProps,
} from "~/components/FlexibleLayouts/elements/Section"

export interface ContactsProps {
  section: SectionProps
  contact: any
}

export const Contacts = ({ section, contact }: ContactsProps) => (
  <Section {...section} paddingOverwrite=" md:!py-[102px]">
    <div className="flex flex-wrap w-full lg:flex-nowrap -ml-[10px] lg:-ml-0 ">
      {contact?.map((heroContact, i, arr) => (
        <div
          key={`heroContact${i}`}
          className={`flex flex-col justify-between xl:max-w-[294px] w-full sm:w-1/2 pl-[10px] lg:pl-0 xl:w-1/4 lg:mr-10  last:mr-0 mb-8`}
        >
          <ContactCard {...heroContact} />
        </div>
      ))}
    </div>
  </Section>
)

export const ContactCard = ({ heading, email, description, contact }) => (
  <>
    <div>
      <div className="h-[10px] bg-beige opacity-30 w-full mb-[32px]"></div>
      <h5 className=" text-[1.2rem] xl:text-[1.5rem] font-extrabold uppercase leading-[1.3]">
        {heading}
      </h5>
      <div className=" font-medium leading-[1.604] mt-2 text-[15px] ">
        {description}
      </div>
    </div>

    <div className="flex items-center mt-5">
      <div className=" font-semibold leading-[1.604] text-[0.775rem] xl:text-16  mr-2">
        <a className="hover:underline" href={"mailto:" + email}>
          {contact}
        </a>
      </div>
      <svg
        width="13"
        height="11"
        viewBox="0 0 13 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 4.59375H10.9673V6.13627H0V4.59375Z" fill="white" />
        <path
          d="M7.88265 10.5343L6.8916 9.45385L10.6422 5.36576L6.8916 1.27768L7.88265 0.197266L12.623 5.36578L7.88265 10.5343Z"
          fill="white"
        />
      </svg>
    </div>
  </>
)
export const layoutQueryFragment = graphql`
  fragment Contacts on WpPage_Page_FlexibleContent {
    ... on WpPage_Page_FlexibleContent_Contacts {
      fieldGroupName
      contact {
        heading
        email
        description
        contact
      }
      ...sectionContacts
    }
  }
`
