import { graphql } from "gatsby"
import React, { useState } from "react"
import { TwoColumnGridWideSecond } from "~/components/FlexibleLayouts/elements/Grid"
import {
  Section,
  SectionProps,
} from "~/components/FlexibleLayouts/elements/Section"

export interface TextWSidebarProps {
  section: SectionProps
  content: any[]
}

export const TextWSidebar = ({ section, content }: TextWSidebarProps) => {
  const [activeTab, setActiveTab] = useState(0)

  return (
    <Section {...section}>
      <TwoColumnGridWideSecond>
        <div className="space-y-5 sticky top-[7.5rem]">
          <h6 className="u-h5 text-14">Contents</h6>
          <ul className="space-y-3">
            {content?.map((content, i) => (
              <li
                onClick={() => setActiveTab(i)}
                key={`content${i}`}
                className={`hover:underline ${
                  activeTab === i
                    ? " font-bold  border-l-[3px] border-red pl-2"
                    : " font-medium"
                }`}
              >
                <a href={`#${i}`}>{content.label}</a>
              </li>
            ))}
          </ul>
        </div>
        <div className="space-y-12">
          {content?.map((content, i) => (
            <div
              id={`${i}`}
              className="prose"
              dangerouslySetInnerHTML={{ __html: content.text }}
            />
          ))}
        </div>
      </TwoColumnGridWideSecond>
    </Section>
  )
}

export const layoutQueryFragment = graphql`
  fragment TextWSidebar on WpPage_Page_FlexibleContent {
    ... on WpPage_Page_FlexibleContent_TextWSidebar {
      ...sectionTextWSidebar
      fieldGroupName
      content {
        label
        text
      }
    }
  }
`
