import React, { useRef, useState } from "react"
import styled from "styled-components"
import tw from "twin.macro"

import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Scrollbar, Mousewheel } from "swiper"
import "swiper/components/scrollbar/scrollbar.scss"
import "swiper/swiper.scss"

import Image from "../Image"
import CustomCursor from "../CustomCursor"

const EventSliderContainer = styled.div`
  .swiper-scrollbar {
    display: none;
  }
`

function EventsSlider({ sliders }) {
  SwiperCore.use([Scrollbar, Mousewheel])

  // console.log(sliders)
  const eventSlider = useRef(null)
  const [activeTab, setActiveTab] = useState(false)

  return (
    <EventSliderContainer className="overflow-visible ">
      <Swiper
        className="flex flex-col w-full"
        slidesPerView={1.1}
        grabCursor="1"
        freeMode={true}
        ref={eventSlider}
        onActiveIndexChange={i => setActiveTab(i.activeIndex)}
        draggable={true}
        mousewheel={true}
        scrollbar={{ draggable: true }}
        style={{ overflow: `visible` }}
      >
        {sliders?.map((slider, i) => (
          <SwiperSlide
            key={`downloadSlider${i}`}
            className="mr-8 bg-slate last:mr-0"
            style={{ height: "auto" }}
          >
            <CustomCursor
              dragCursor
              last={activeTab === sliders.length - 1 ? true : false}
            >
              <div className="flex flex-col bg-white items-center md:flex-row lg:h-full max-h-[519px]">
                <div className="px-3 py-6 md:px-10 xl:px-24">
                  <div className="uppercase text-10 text-black opacity-70 tracking-[0.05em] leading-[1.109] font-semibold mb-5 ">
                    {slider?.date}
                  </div>
                  <h2 className="font-extrabold u-h2b text-28 md:text-[42px] mb-7 lg:max-w-[417px] leading-[1.124]">
                    {slider?.heading}
                  </h2>
                  <div
                    className={` u-p1 text-13 md:text-16 lg:max-w-[417px]`}
                    dangerouslySetInnerHTML={{ __html: slider?.content }}
                  />
                </div>
                <div className="md:max-w-[519px] xl:max-w-[597px] hidden z-0 lg:block h-full w-full relative pointer-events-none">
                  <Image
                    objectFit="cover"
                    className="z-0 w-full h-full"
                    image={slider?.image}
                  />
                </div>
              </div>
              <div className="flex flex-wrap w-full px-5 py-10 text-white xl:pl-[72px] xl:pr-[51px] md:flex-nowrap md:justify-around bg-slate">
                {slider?.stats?.map((stat, i, arr) => (
                  <div
                    key={`sliderDownload${stat?.stat}${i}`}
                    className={`flex flex-col items-start border-l-[5px] border-beige pl-3 md:pl-[25px] mb-4 md:mb-0 w-1/2 md:w-full`}
                  >
                    <div className="my-2">
                      <h5 className=" u-h5 text-18 lg:text-42 font-extrabold leading-[0.98] tracking-[-0.015em] mb-2.5 ">
                        {stat?.stat}
                      </h5>
                      <div className=" font-medium text-14 leading-[1.109] tracking-[-0.015em] ">
                        {stat?.description}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </CustomCursor>
          </SwiperSlide>
        ))}
      </Swiper>
    </EventSliderContainer>
  )
}

export default EventsSlider
