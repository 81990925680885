import { graphql } from "gatsby"
import React from "react"
import { Icon, IconType } from "~/components/FlexibleLayouts/elements/Icons"
import { Links } from "~/components/FlexibleLayouts/elements/Link"
import {
  Section,
  SectionProps,
} from "~/components/FlexibleLayouts/elements/Section"
import {
  BodyCopy,
  BodyCopySizeOption,
  Heading,
  HeadingSizeOption,
  SecondarySubheading,
  Subheading,
  TertiarySubheading,
} from "~/components/FlexibleLayouts/elements/Typography"

export interface LegalHeroProps {
  coreCopyStack: CoreCopyStackProps
  section: SectionProps
  subheadingType: string
  bodySize: string
  lastUpdated: string
}

export const LegalHero = ({
  coreCopyStack,
  section,
  subheadingType,
  bodySize,
  lastUpdated,
}: LegalHeroProps) => {
  let headingSize = HeadingSizeOption.Large
  if (bodySize === "medium" || bodySize === "large") {
    headingSize = HeadingSizeOption.XLarge
  }
  return (
    <Section {...section}>
      <div className="pt-[30px] md:pt-[60px] xl:pt-0">
        {subheadingType === "primary" ? (
          <div className="pt-12 mb-5">
            <Subheading> {coreCopyStack?.subheading}</Subheading>
          </div>
        ) : subheadingType === "secondary" ? (
          <div className="pt-12 mb-5">
            <SecondarySubheading>
              {coreCopyStack?.subheading}
            </SecondarySubheading>
          </div>
        ) : (
          <div className="min-h-[50px] xl:min-h-[140px]">
            <TertiarySubheading Type="h2">
              <span className="inline-block mr-4 relative top-[4px]">
                <Icon type={IconType.Cursor} />
              </span>
              {coreCopyStack?.subheading}
            </TertiarySubheading>
          </div>
        )}
        <Heading Type="h1" size={headingSize}>
          {coreCopyStack?.heading}
        </Heading>

        {lastUpdated && (
          <div className="mt-[16px] text-[15px] leading-[23px] font-medium text-beige flex items-center  ">
            <svg
              width="11"
              height="11"
              viewBox="0 0 11 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.5 0C4.0415 0 2.64217 0.579609 1.61081 1.61081C0.579555 2.64206 0 4.04137 0 5.5C0 6.95863 0.579609 8.35783 1.61081 9.38919C2.64206 10.4204 4.04137 11 5.5 11C6.95863 11 8.35783 10.4204 9.38919 9.38919C10.4204 8.35794 11 6.95863 11 5.5C10.9984 4.04191 10.4183 2.64366 9.38703 1.61297C8.3563 0.581721 6.95809 0.00162462 5.5 0ZM7.91472 7.91472C7.74919 8.07973 7.4816 8.07973 7.31606 7.91472L5.20068 5.79934C5.12135 5.72001 5.07693 5.61213 5.07693 5.50001V1.69232C5.07693 1.45857 5.26625 1.26924 5.50001 1.26924C5.73376 1.26924 5.92308 1.45857 5.92308 1.69232V5.32496L7.91473 7.3166V7.31608C8.07973 7.48161 8.07973 7.7492 7.91473 7.91473L7.91472 7.91472Z"
                fill="#D5D2CA"
              />
            </svg>
            <span className="ml-[13px]">Last Updated: {lastUpdated}</span>
          </div>
        )}
        <div className="pt-4 md:pt-8">
          <BodyCopy
            size={
              bodySize === "small"
                ? BodyCopySizeOption.Small
                : bodySize === "medium"
                ? BodyCopySizeOption.Medium
                : bodySize === "large"
                ? BodyCopySizeOption.Large
                : null
            }
          >
            {coreCopyStack?.text}
          </BodyCopy>
        </div>
        {coreCopyStack?.links && (
          <div className="pt-[8px] md:pt-[20px] xl:pt-[50px]">
            <Links links={coreCopyStack?.links} />
          </div>
        )}
      </div>
    </Section>
  )
}

export const layoutQueryFragment = graphql`
  fragment LegalHero on WpPage_Page_FlexibleContent {
    ... on WpPage_Page_FlexibleContent_LegalHero {
      fieldGroupName
      ...coreCopyStackLegalHero
      ...sectionLegalHero
      subheadingType
      bodySize
      lastUpdated
    }
  }
`
