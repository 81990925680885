import { graphql } from "gatsby"
import React from "react"
import { Links } from "~/components/FlexibleLayouts/elements/Link"
import {
  Section,
  SectionProps,
} from "~/components/FlexibleLayouts/elements/Section"
import {
  BodyCopy,
  BodyCopySizeOption,
  CoreCopyStackProps,
  Heading,
  HeadingSizeOption,
  Subheading,
} from "~/components/FlexibleLayouts/elements/Typography"

export interface MidpageHeroProps {
  coreCopyStack: CoreCopyStackProps
  section: SectionProps
}

export const MidpageHero = ({ coreCopyStack, section }: MidpageHeroProps) => (
  <Section {...section}>
    <div className="xl:py-[30px]">
      <Subheading Type="h3">{coreCopyStack.subheading}</Subheading>
      <div className="max-w-[940px] py-6">
        <Heading Type="h2" size={HeadingSizeOption.Large}>
          {coreCopyStack.heading}
        </Heading>
      </div>
      <div className="max-w-[670px]">
        <BodyCopy size={BodyCopySizeOption.Small}>
          {coreCopyStack.text}
        </BodyCopy>
      </div>
      {coreCopyStack.links && (
        <div className="pt-[8px] md:pt-[20px] xl:pt-[50px]">
          <Links links={coreCopyStack.links} />
        </div>
      )}
    </div>
  </Section>
)

export const layoutQueryFragment = graphql`
  fragment MidpageHero on WpPage_Page_FlexibleContent {
    ... on WpPage_Page_FlexibleContent_MidpageHero {
      fieldGroupName
      ...coreCopyStackMidpageHero
      ...sectionMidpageHero
    }
  }
`
