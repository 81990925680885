import { graphql } from "gatsby"
import React from "react"
import {
  Section,
  SectionProps,
} from "~/components/FlexibleLayouts/elements/Section"
import {
  CoreCopyStack,
  CoreCopyStackProps,
} from "~/components/FlexibleLayouts/elements/Typography"
import Image from "~/components/Image"

export interface OfficesProps {
  coreCopyStack: CoreCopyStackProps
  section: SectionProps
  offices: any[]
}

export const Offices = ({ coreCopyStack, section, offices }: OfficesProps) => (
  <Section {...section} noContainer paddingOverwrite="!pb-0">
    <div className="container">
      <div className="max-w-[613px]">
        <CoreCopyStack {...coreCopyStack} />
      </div>
    </div>

    <div className="relative mx-auto mt-12 lg:mt-28">
      {offices?.map((office, i) => (
        <div
          key={`contactOffice${i}`}
          className="flex flex-col items-center lg:flex-row "
        >
          <div className="w-full h-full lg:mr-[7.5rem] lg:w-1/2">
            <div className="max-w-[720px]">
              <Image image={office?.image} />
            </div>
          </div>
          <div className="flex flex-col lg:max-w-[23.563rem] px-6 lg:px-0 my-12 lg:my-0 w-full">
            <h2 className="text-[30px] xl:text-55 leading-[0.944] font-bold uppercase mb-10 lg:mb-[50px] xl:mb-[85px] ">
              {office?.country}
            </h2>
            <div className="flex flex-wrap ml-[-3.5rem]">
              {office?.addresses?.map((address, i, arr) => (
                <div
                  key={`contractAddress${address?.heading}${i}`}
                  className={` ${arr.length > 1 && "w-1/2 "} ${
                    arr.length > 2 && "last:mt-12"
                  } pl-[3.5rem] offices  `}
                >
                  <div className="mb-3 font-extrabold uppercase whitespace-nowrap">
                    {address?.heading}
                  </div>
                  <p>{address?.addressLine1}</p>
                  <p>{address?.addressLine2}</p>
                  <p>{address?.addressLine3}</p>
                  <p>{address?.addressLine4}</p>
                </div>
              ))}
            </div>
            {office?.number && (
              <div className="mt-8 font-extrabold uppercase lg:mt-24">
                {office?.number}
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  </Section>
)

export const layoutQueryFragment = graphql`
  fragment Offices on WpPage_Page_FlexibleContent {
    ... on WpPage_Page_FlexibleContent_Offices {
      fieldGroupName
      ...coreCopyStackOffices
      ...sectionOffices
      offices {
        image {
          ...Image
        }
        country
        addresses {
          heading
          addressLine1
          addressLine2
          addressLine3
          addressLine4
        }
        number
      }
    }
  }
`
