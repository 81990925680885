import { graphql, useStaticQuery } from "gatsby"

const useJobs = () => {
  const data = useStaticQuery(graphql`
    query {
      allWorkableJob(limit: 10) {
        nodes {
          title
          department
          location {
            country
            location_str
          }
          application_url
          id
          url
        }
      }
    }
  `)

  return data
}

export default useJobs
