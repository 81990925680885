import React from "react"

function TimelineButton({ className = "", onClick, fill = "black", disabled = false }) {
  return (
    <div className={`${className} group ${disabled ? "opacity-40" : ""}`} onClick={onClick}>
      <div
        className={`border border-opacity-20 rounded-full transition-all h-14 ${
          fill === "white"
            ? "border-white text-white group-hover:bg-white group-hover:border-opacity-100 group-hover:text-black"
            : "border-black group-hover:border-opacity-100 group-hover:bg-black group-hover:text-white"
        }`}
      >
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 76 60"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="-0.5"
            y="0.5"
            width="75"
            height="58"
            rx="29"
            transform="matrix(-1 0 0 1 75 0.546875)"
            strokeOpacity="0.2"
          />
          <path
            d="M44 29.5801H33.0327V30.995H44V29.5801Z"
            fill="currentColor"
          />
          <path
            d="M36.1183 35.0289L37.1094 34.0379L33.3587 30.2879L37.1094 26.5379L36.1183 25.5469L31.378 30.2879L36.1183 35.0289Z"
            fill="currentColor"
          />
        </svg>
      </div>
    </div>
  )
}

export default TimelineButton
