import { graphql } from "gatsby"
import React from "react"
import FinancialHighlights from "~/components/FinancialHighlights"
import {
  Section,
  SectionProps,
} from "~/components/FlexibleLayouts/elements/Section"
import { CoreCopyStackProps } from "~/components/FlexibleLayouts/elements/Typography"

export interface HighlightsProps {
  coreCopyStack: CoreCopyStackProps
  section: SectionProps
  highlights: any[]
}

export const Highlights = ({
  coreCopyStack,
  section,
  highlights,
}: HighlightsProps) => (
  <Section {...section}>
    <FinancialHighlights text={coreCopyStack} highlights={highlights} />
  </Section>
)

export const layoutQueryFragment = graphql`
  fragment Highlights on WpPage_Page_FlexibleContent {
    ... on WpPage_Page_FlexibleContent_Highlights {
      fieldGroupName
      ...coreCopyStackHighlights
      ...sectionHighlights
      highlights {
        label
        heading
        highlights {
          numberPrefix
          number
          numberAffix
          description
        }
      }
    }
  }
`
