import { graphql } from "gatsby"
import React, { useState } from "react"
import {
  Section,
  SectionProps,
} from "~/components/FlexibleLayouts/elements/Section"
import {
  CoreCopyStack,
  CoreCopyStackProps,
} from "~/components/FlexibleLayouts/elements/Typography"
import StrategyTable from "~/components/tables/StrategyTable"
import DownArrow from "~/images/down-arrow.svg"
import { TwoColMediaWithList } from "./TwoColMediaWithList"
import { TwoColumnGridList } from "./TwoColumnGridList"
import { TwoColumnList } from "./TwoColumnList"
import { TwoColumnTextInner } from "./TwoColumnTextInner"

export interface TabbedSectionProps {
  section: SectionProps
  coreCopyStack: CoreCopyStackProps
  tabbedSection: {
    sectionType: string
    tabLabel: string
    table: any
    twoColumnList: any
    twoColumnMediaText: any
    twoColumnText: any
    gridList: any
  }
}

export const TabbedSection = ({
  coreCopyStack,
  section,
  tabbedSection,
}: TabbedSectionProps) => {
  const [activeTab, setActiveTab] = useState(0)

  return (
    <Section {...section}>
      <div className="max-w-[895px]">
        <CoreCopyStack {...coreCopyStack} />
      </div>

      <div className="ml-[-18px] mt-[70px] hidden lg:flex">
        {tabbedSection?.map((tab, i) => (
          <div
            onClick={() => setActiveTab(i)}
            key={`tab${i}`}
            className="pl-[18px]"
          >
            <div
              className={` rounded-[7px] uppercase text-[15px] font-extrabold border border-brown cursor-pointer py-[10px] px-[20px] transition-colors duration-200 ease-in-out ${
                activeTab === i
                  ? "bg-black text-white"
                  : "bg-white text-brown hover:bg-black hover:text-white"
              } `}
            >
              {tab?.tabLabel}
            </div>
          </div>
        ))}
      </div>

      <form action="" className="lg:hidden mt-[70px] relative">
        <div className="relative inset-0">
          <img
            src={DownArrow}
            alt="down arrow"
            className="absolute top-[35%] right-[1.5rem] h-[15px]"
          />
          <select
            className="text-[16px] uppercase leading-[1.439] focus:outline-none font-bold border w-full lg:w-auto rounded-md border-black py-3 px-5 appearance-none  "
            onChange={e => setActiveTab(e.target.value)}
          >
            {tabbedSection?.map((tab, i) => (
              <option key={i} value={i}>
                {tab?.tabLabel}
              </option>
            ))}
          </select>
        </div>
      </form>

      <div className=" mt-12 lg:mt-[100px]">
        {tabbedSection[activeTab]?.twoColumnList &&
          tabbedSection[activeTab]?.sectionType === "twoColList" && (
            <TwoColumnList
              {...tabbedSection[activeTab]?.twoColumnList}
              noContainer={true}
              disableAnimation
              paddingOverwrite="!py-0"
            />
          )}
        {tabbedSection[activeTab]?.gridList &&
          tabbedSection[activeTab]?.sectionType === "gridList" && (
            <TwoColumnGridList
              {...tabbedSection[activeTab]?.gridList}
              noContainer
              paddingOverwrite="!py-0"
            />
          )}
        {tabbedSection[activeTab]?.twoColumnMediaText &&
          tabbedSection[activeTab]?.sectionType === "twoColMedia" && (
            <TwoColMediaWithList
              {...tabbedSection[activeTab]?.twoColumnMediaText}
              paddingOverwrite="!py-0"
              noContainer
            />
          )}
        {tabbedSection[activeTab]?.table &&
          tabbedSection[activeTab]?.sectionType === "table" && (
            <StrategyTable table={tabbedSection[activeTab]?.table} />
          )}
        {tabbedSection[activeTab]?.twoColumnText &&
          tabbedSection[activeTab]?.sectionType === "twoColText" && (
            <TwoColumnTextInner {...tabbedSection[activeTab]?.twoColumnText} />
          )}
      </div>
    </Section>
  )
}

export const layoutQueryFragment = graphql`
  fragment TabbedSection on WpPage_Page_FlexibleContent {
    ... on WpPage_Page_FlexibleContent_TabbedSection {
      fieldGroupName
      ...sectionTabbedSection
      ...coreCopyStackTabbedSection

      tabbedSection {
        sectionType
        tabLabel
        table {
          date
          name
          contentAndCapability {
            type
            text
          }
          multiples {
            content
          }
        }
        twoColumnList {
          coreCopyStack {
            text
            subheading
            heading
            links {
              type
              icon
              link {
                url
                title
              }
            }
          }
          modal {
            heading
            content
            image {
              ...Image
            }
          }
          modalButton
          list {
            text
          }
        }
        twoColumnMediaText {
          twoColMedia {
            subheading
            heading
            text
            links {
              videoEmbed
              email
              behaviour
              file {
                localFile {
                  publicURL
                }
              }
              link {
                title
                url
              }
              type
              icon
            }
            image {
              ...Image
            }
          }
          list {
            secondaryCopyStack {
              text
              subheading
              heading
            }
          }
        }
        twoColumnText {
          subheading
          heading
          contentLeft
          contentRight
          icon
          type
          modalButton
          modal {
            image {
              ...Image
            }
            heading
            content
          }
          link {
            url
            title
          }
        }
        gridList {
          alternateVersion
          coreCopyStack {
            text
            subheading
            heading
            links {
              type
              icon
              link {
                url
                title
              }
            }
          }
          list {
            secondaryCopyStack {
              text
              subheading
              heading
            }
          }
        }
      }
    }
  }
`
