import React, { Fragment } from "react"
import { graphql } from "gatsby"
import {
  Section,
  SectionProps,
} from "~/components/FlexibleLayouts/elements/Section"
import {
  CoreCopyStackProps,
  CoreCopyStack,
} from "~/components/FlexibleLayouts/elements/Typography"
import Image from "~/components/Image"

export interface WProps {
  section: SectionProps
  coreCopyStack: CoreCopyStackProps
  image: any
}

export const FullWidthImage = ({ coreCopyStack, section, image }: WProps) => (
  <Section {...section}>
    <CoreCopyStack {...coreCopyStack} />

    <div className="mt-[53px]">
      <Image image={image} />
    </div>
  </Section>
)

export const layoutQueryFragment = graphql`
  fragment FullWidthImage on WpPage_Page_FlexibleContent {
    ... on WpPage_Page_FlexibleContent_FullWidthImage {
      fieldGroupName
      ...sectionFullWidthImage
      ...coreCopyStackFullWidthImage
      image {
        ...Image
      }
    }
  }
`
