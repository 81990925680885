import React, { useState } from "react"
import {
  Section,
  SectionProps,
} from "~/components/FlexibleLayouts/elements/Section"
import { Subheading } from "~/components/FlexibleLayouts/elements/Typography"
import { Link, LinkType } from "../elements/Link"
import { IconType } from "../elements/Icons"
import { Modal } from "./TwoColumnGridList"
import { AnimatePresence, motion } from "framer-motion"

export interface TextCardSectionProps {
  section: SectionProps

  contentLeft: string
  contentRight: string
  heading: string
  icon: IconType
  link: any
  type: any
  subheading: string
  modal: any
}

export const TwoColumnTextInner = ({
  contentLeft,
  contentRight,
  heading,

  subheading,
  section,
  modal,
}: TextCardSectionProps) => {
  const [showModal, setShowModal] = useState(false)
  return (
    <Section {...section} paddingOverwrite="!py-0" noContainer>
      {subheading && <Subheading>{subheading}</Subheading>}

      <div className="mt-[19px] mb-6 md:mb-[51px] font-semibold text-[24px] leading-[25px] md:text-[24px] md:leading-[1.484] max-w-[756px] ">
        {heading}
      </div>

      <div className="md:flex md:space-x-5 lg:space-x-[83px] font-medium text-[15px] md:text-[17px] leading-[25px] md:leading-[1.76] mb-[51px]">
        {contentLeft && (
          <div
            className={`md:max-w-[468px] `}
            dangerouslySetInnerHTML={{ __html: contentLeft }}
          />
        )}
        {contentRight && (
          <div
            className={` md:max-w-[468px]  `}
            dangerouslySetInnerHTML={{ __html: contentRight }}
          />
        )}
      </div>

      <div className="mt-6 cursor-pointer" onClick={() => setShowModal(true)}>
        <Link
          link={{ title: "Learn More" }}
          icon={IconType.ArrowRight}
          type={LinkType.Button}
        />
      </div>

      <AnimatePresence>
        {showModal && <Modal {...modal} setShowModal={setShowModal} />}
      </AnimatePresence>

      <AnimatePresence>
        {showModal && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
            className="fixed top-0 bottom-0 left-0 right-0 bg-black bg-opacity-40"
            onClick={() => setShowModal(false)}
          ></motion.div>
        )}
      </AnimatePresence>
    </Section>
  )
}
