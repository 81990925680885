import React, { Fragment, useState } from "react"
import { graphql } from "gatsby"
import {
  Section,
  SectionProps,
} from "~/components/FlexibleLayouts/elements/Section"
import {
  CoreCopyStackProps,
  CoreCopyStack,
  Heading,
  HeadingSizeOption,
} from "~/components/FlexibleLayouts/elements/Typography"
import DefaultButton from "~/components/buttons/DefaultButton"
import { AnimatePresence, motion } from "framer-motion"
import styled from "styled-components"

export interface FlexibleNewsletterProps {
  section: SectionProps
  coreCopyStack: CoreCopyStackProps
}

const FrameContainer = styled.div`
  input {
    background-color: black;
  }
`

export const FlexibleNewsletter = ({
  coreCopyStack,
  section,
}: FlexibleNewsletterProps) => {
  const [showModal, setShowModal] = useState(false)

  return (
    <Section {...section} noContainer paddingOverwrite="!py-0">
      <div className="text-white bg-red">
        <div className="container">
          <div className="py-20 md:py-32 lg:py-[216px]">
            <div className="max-w-[821px]">
              <Heading size={HeadingSizeOption.Large}>
                {coreCopyStack?.heading}{" "}
              </Heading>
            </div>
            <div className="mt-12 max-w-max">
              <DefaultButton
                onClick={() => setShowModal(true)}
                white
                containerClass="w-full md:w-auto cursor-pointer "
                motionDivClass="h-full"
                className="justify-center h-full px-12 py-6 mt-4 text-black pointer-events-none md:mt-0"
                title={`Subscribe`}
              />
            </div>
          </div>
        </div>
      </div>

      <NewsletterModal setShowModal={setShowModal} showModal={showModal} />
    </Section>
  )
}

export const NewsletterModal = ({ showModal, setShowModal }) => {
  return (
    <>
      {showModal && (
        <motion.div
          onClick={() => setShowModal(false)}
          className=" fixed left-0 right-0 top-[10%] bottom-[10%]   z-[150] "
        >
          <div className="flex items-center justify-center w-full h-full px-5 ">
            <div className="bg-white w-full h-full max-w-[600px] max-h-[800px] z-[160] rounded-lg ">
              <FrameContainer className="w-full h-full">
                <iframe
                  className=" w-full max-w-[600px] h-full rounded-lg px-5"
                  src="https://futureplc.slgnt.eu/optiext/optiextension.dll?ID=PbkPm7iGxfWDM6S7hr4Hz1rZh%2B1M%2BSiC8674eSYhFFXJ77e5yWSWP9LLzBcBj0tH%2Beo4VtBpmGH11Kqr7y"
                  style={{ border: "0px #ffffff none" }}
                  name="Future Licensing iFrame"
                  scrolling="yes"
                  frameborder="1"
                  marginheight="0px"
                  marginwidth="0px"
                  height="1000"
                  width="100%"
                  allowfullscreen
                ></iframe>
              </FrameContainer>
            </div>
          </div>
        </motion.div>
      )}

      <AnimatePresence>
        {showModal && (
          <motion.div
            onClick={() => setShowModal(false)}
            key={`modal`}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
            className="fixed top-0 bottom-0 left-0 right-0  bg-black bg-opacity-40 z-[140]"
          ></motion.div>
        )}
      </AnimatePresence>
    </>
  )
}

export const layoutQueryFragment = graphql`
  fragment FlexibleNewsletter on WpPage_Page_FlexibleContent {
    ... on WpPage_Page_FlexibleContent_FlexibleNewsletter {
      fieldGroupName
      ...coreCopyStackFlexibleNewsletter
      ...sectionFlexibleNewsletter
    }
  }
`
