import { graphql } from "gatsby"
import React from "react"
import {
  Section,
  SectionProps,
} from "~/components/FlexibleLayouts/elements/Section"
import {
  CoreCopyStack,
  CoreCopyStackProps,
} from "~/components/FlexibleLayouts/elements/Typography"
import DownloadsGrid from "~/components/grids/DownloadsGrid"

export interface DownloadGridProps {
  section: SectionProps
  coreCopyStack: CoreCopyStackProps
  downloads: any[]
}

export const DownloadGrid = ({
  coreCopyStack,
  downloads,
  section,
}: DownloadGridProps) => (
  <Section {...section}>
    <CoreCopyStack
      {...coreCopyStack}
      headingClass="md:!text-[60px] md:!leading-[0.944] "
    />

    <DownloadsGrid showDescription downloads={downloads} />
  </Section>
)

export const layoutQueryFragment = graphql`
  fragment DownloadGrid on WpPage_Page_FlexibleContent {
    ... on WpPage_Page_FlexibleContent_DownloadGrid {
      fieldGroupName
      ...coreCopyStackDownloadGrid
      ...sectionDownloadGrid
      downloads {
        heading
        content
        downloads {
          downloadText
          file {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`
