import { graphql } from "gatsby"
import React from "react"
import DefaultButton from "~/components/buttons/DefaultButton"
import {
  Section,
  SectionProps,
} from "~/components/FlexibleLayouts/elements/Section"
import {
  BodyCopySizeOption,
  CoreCopyStack,
} from "~/components/FlexibleLayouts/elements/Typography"
import HomeResourcePreview from "~/components/resources/HomeResourcePreview"

export interface FeaturedNewsProps {
  coreCopyStack: CoreCopyStackProps
  section: SectionProps
  blogPostsFetch: blogPostsFetch
  button: any
  posts: any
}

export const FeaturedNews = ({
  coreCopyStack,
  section,
  button,
  posts,
}: FeaturedNewsProps) => {
  return (
    <Section {...section}>
      <div className="max-w-2xl mr-auto">
        <CoreCopyStack bodySize={BodyCopySizeOption.Small} {...coreCopyStack} />
      </div>
      <div className="flex flex-wrap mt-12 md:mt-20 md:flex-nowrap ">
        <div className="relative w-full md:w-5/12">
          <div className="sticky top-[9.5rem]">
            <HomeResourcePreview {...posts[0]} noBorder />
          </div>
        </div>
        <div className="relative w-full md:ml-12 md:w-7/12 ">
          <div className="flex flex-wrap mt-12 md:flex-nowrap md:mt-0 -ml-14">
            <div className="flex flex-col w-full md:w-1/2 pl-14">
              {posts?.slice(1, 4)?.map((resource, i, arr) => (
                <HomeResourcePreview
                  key={`homeResourceCol1${i}`}
                  noImage={i === 0 ? true : false}
                  noBorder={i === arr.length - 1 ? true : false}
                  {...resource}
                />
              ))}
            </div>
            <div className="flex flex-col w-full md:w-1/2 pl-14">
              {posts?.slice(4, 8)?.map((resource, i, arr) => (
                <HomeResourcePreview
                  key={`homeResourceCol2${i}`}
                  noImage={i === 0 ? false : true}
                  noBorder={i === arr.length - 1 ? true : false}
                  {...resource}
                />
              ))}
            </div>
          </div>
        </div>
      </div>

      <DefaultButton
        className="relative mx-auto mt-12 max-w-max md:mt-24"
        {...button}
      />
    </Section>
  )
}

export const layoutQueryFragment = graphql`
  fragment FeaturedNews on WpPage_Page_FlexibleContent {
    ... on WpPage_Page_FlexibleContent_FeaturedNews {
      fieldGroupName
      ...coreCopyStackFeaturedNews
      ...sectionFeaturedNews

      button {
        url
        title
      }
      posts {
        ... on WpPost {
          id
          uri
          categories {
            nodes {
              name
            }
          }
          featuredImage {
            node {
              ...Image
            }
          }
          title
          date(formatString: "MMMM d YYYY")
        }
      }
    }
  }
`
