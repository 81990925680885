import { motion } from "framer-motion"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React, { useState } from "react"
import Image from "../Image"

function HomeResourcePreview({
  noBorder = false,
  noImage = false,
  categories,
  title,
  featuredImage,
  date,
  uri,
  ...other
}) {
  const [onHover, setOnHover] = useState(false)
  return (
    <div
      onMouseEnter={() => setOnHover(true)}
      onMouseLeave={() => setOnHover(false)}
    >
      <Link
        to={uri || "#"}
        className="flex flex-col mb-5 group md:mb-10"
        {...other}
      >
        <motion.div
          initial={{ opacity: 1 }}
          animate={{ opacity: onHover ? 0.5 : 1 }}
          className="mb-6 overflow-hidden"
        >
          {!noImage && (
            <Image
              className="transition-transform duration-700 ease-in-out transform group-hover:scale-110"
              image={featuredImage?.node}
            />
          )}
        </motion.div>
        <motion.div
          initial={{ opacity: 1 }}
          animate={{ opacity: onHover ? 0.5 : 1 }}
        >
          <div className="flex  items-center uppercase text-brown text-[12px] font-extrabold leading-[1.109] tracking-[0.05em] ">
            <div className="mr-[22px]">{categories?.nodes[0]?.name}</div>
            <div className="font-semibold">{date}</div>
          </div>
          <h5 className="mt-4 u-h5 text-18 lg:text-24 ">{title}</h5>
        </motion.div>
        {!noBorder && (
          <div className="w-full h-px mt-6 rounded-full md:mt-14 bg-beige"></div>
        )}
      </Link>
    </div>
  )
}

export default HomeResourcePreview
