import { AnimatePresence, motion } from "framer-motion"
import { graphql } from "gatsby"
import React, { useState } from "react"
import {
  Section,
  SectionProps,
} from "~/components/FlexibleLayouts/elements/Section"
import {
  CoreCopyStack,
  CoreCopyStackProps,
  Heading,
  HeadingSizeOption,
} from "~/components/FlexibleLayouts/elements/Typography"
import Image from "~/components/Image"

export interface TeamMembersProps {
  coreCopyStack: CoreCopyStackProps
  section: SectionProps
  teamMembers: any[]
}

export const TeamMembers = ({
  coreCopyStack,
  section,
  teamMembers,
}: TeamMembersProps) => {
  const [showModal, setShowModal] = useState(false)
  const [activeMember, setActiveMember] = useState(null)
  const [activeMemberGroup, setActiveMemberGroup] = useState(null)

  const modalHandler = (i, index) => {
    setActiveMember(i)
    setActiveMemberGroup(index)
    setShowModal(true)
  }

  let last = teamMembers[activeMemberGroup]?.teamMember?.length - 1

  return (
    <Section {...section}>
      <div className="max-w-[830px]">
        <CoreCopyStack
          headingSize={HeadingSizeOption.Large}
          {...coreCopyStack}
        />
      </div>

      <div className="mt-12 md:mt-[119px] ">
        {teamMembers?.map((memberGroup, index) => (
          <div key={`memberGroup${index}`}>
            {memberGroup?.heading && (
              <div className="mt-[108px]">
                <Heading size={HeadingSizeOption.Small}>
                  {memberGroup?.heading}{" "}
                </Heading>
              </div>
            )}
            <div className="grid grid-cols-2 md:grid-cols-4 gap-6 md:gap-x-[27px] md:gap-y-[65px] mt-[44px]">
              {memberGroup?.teamMember?.map((teamMember, i) => (
                <div
                  className="cursor-pointer peer "
                  key={`member${i}`}
                  onClick={() => modalHandler(i, index)}
                >
                  <TeamMember i={i} {...teamMember?.teamMember} />
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      <AnimatePresence>
        {showModal && (
          <TeamModal
            {...teamMembers[activeMemberGroup]?.teamMember[activeMember]
              ?.teamMember}
            setShowModal={setShowModal}
            setActiveMember={setActiveMember}
            activeMember={activeMember}
            previousMember={
              teamMembers[activeMemberGroup]?.teamMember[activeMember - 1]
                ?.teamMember
            }
            nextMember={
              teamMembers[activeMemberGroup]?.teamMember[activeMember + 1]
                ?.teamMember
            }
            last={last}
          />
        )}
      </AnimatePresence>

      <AnimatePresence>
        {showModal && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
            className="fixed top-0 bottom-0 left-0 right-0 bg-black bg-opacity-40"
            onClick={() => setShowModal(false)}
          ></motion.div>
        )}
      </AnimatePresence>
    </Section>
  )
}

export const TeamModal = ({
  i,
  name,
  image,
  jobTitle,
  links,
  description,
  setShowModal,
  setActiveMember,
  activeMember,
  last,
  previousMember,
  nextMember,
}) => {
  return (
    <motion.div
      key={`modal`}
      initial={{ x: "-100%" }}
      animate={{ x: "0%" }}
      exit={{ x: "-100%" }}
      transition={{ duration: 0.3, ease: "easeInOut" }}
      className="fixed top-0 bottom-0 left-0 z-[150] overflow-scroll bg-black scrollbar-hidden text-white"
    >
      <div className=" px-6 pt-24 pb-20 lg:pl-[105px] lg:pt-[121px] lg:pb-[77px] lg:pr-[139px]  relative">
        <div className="absolute top-[2rem] right-[1.5rem] md:right-[2.5rem]">
          <svg
            className="cursor-pointer"
            onClick={() => setShowModal(false)}
            width="47"
            height="47"
            viewBox="0 0 47 47"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="23.5" cy="23.5" r="23.5" fill="white" />
            <circle
              cx="23.5"
              cy="23.5"
              r="22.7295"
              stroke="black"
              strokeOpacity="0.12"
              strokeWidth="1.54098"
            />
            <path
              d="M17 18L29 30"
              stroke="#231F20"
              strokeWidth="1.5"
              strokeLinecap="square"
              strokeLinejoin="round"
            />
            <path
              d="M29 18L17 30"
              stroke="#231F20"
              strokeWidth="1.5"
              strokeLinecap="square"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <div className="max-w-[313px] max-h-[366px]">
          <Image
            image={image}
            className="max-w-[313px] max-h-[366px]"
            objectFit="cover"
          />
        </div>
        <div className="mt-[60px] md:min-w-[467px] max-w-[467px]">
          <div className="text-[32px] leading-[30px] uppercase font-bold   ">
            {name}
          </div>
          <div className="uppercase text-[21px] leading-[30px] opacity-[0.36] font-medium mt-1  ">
            {jobTitle}
          </div>
          <div
            className="mt-[28px] text-[17px] leading-[30px] font-medium  "
            dangerouslySetInnerHTML={{ __html: description }}
          />
          <div className="mt-[38px]">
            <div className="flex">
              {links?.map((link, i) => (
                <ModalLink key={`link${i}`} {...link?.link} />
              ))}
            </div>
          </div>
        </div>

        <div className="flex items-center justify-between w-full mt-[87px]">
          <DirectionArrow
            className={`${
              activeMember === 0 ? "opacity-0 pointer-events-none" : ""
            } rotate-180`}
            onClick={() => setActiveMember(activeMember => activeMember - 1)}
            prev={previousMember?.name}
          />
          <DirectionArrow
            className={`${
              activeMember === last ? "opacity-0 pointer-events-none" : ""
            } self-end`}
            onClick={() => setActiveMember(activeMember => activeMember + 1)}
            next={nextMember?.name}
          />
        </div>
      </div>
    </motion.div>
  )
}

export const TeamMember = ({ i, name, image, jobTitle }) => (
  <div
    key={i}
    data-value={`${i}`}
    className="font-semibold leading-[1.439] text-[15px] md:text-[16px] group transition-opacity duration-200 ease-in-out  "
  >
    <Image
      className="transition-opacity duration-200 ease-in-out group-hover:opacity-70"
      image={image}
    />
    <div className="mt-[18px] group-hover:opacity-70 transition-opacity duration-200 ease-in-out ">
      {name}
    </div>
    <div className="mt-px text-brown text-[13px] group-hover:opacity-70 transition-opacity duration-200 ease-in-out ">
      {jobTitle}
    </div>
  </div>
)

export const ModalLink = ({ title, url }) => (
  <a href={url} target="_blank" className="flex items-center">
    <span className="mr-[14px] hover:underline transition-transform duration-200 text-[15px] leading-[18px] font-semibold ">
      {title}
    </span>

    <svg
      width="13"
      height="11"
      viewBox="0 0 13 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 4.59375L10.9673 4.59375V6.13627L0 6.13627L0 4.59375Z"
        fill="white"
      />
      <path
        d="M7.88168 10.5343L6.89063 9.45385L10.6413 5.36576L6.89062 1.27768L7.88168 0.197266L12.622 5.36578L7.88168 10.5343Z"
        fill="white"
      />
    </svg>
  </a>
)

export const DirectionArrow = ({ className, onClick, next, prev }) => {
  const [onHover, setOnHover] = useState(false)

  return (
    <div
      className={`cursor-pointer  flex-col ${
        next && " flex-col-reverse self-end"
      } md:flex-row flex md:items-center`}
      onMouseEnter={() => setOnHover(true)}
      onMouseLeave={() => setOnHover(false)}
      onClick={onClick}
    >
      {next && (
        <div
          className={`text-[14px] font-semibold leading-[19px] md:mr-[15px] mt-3 md:mt-0`}
        >
          {next}
        </div>
      )}
      <svg
        className={`${className}`}
        width="48"
        height="27"
        viewBox="0 0 48 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          width="47.25"
          height="27"
          rx="13.5"
          fill="white"
          fillOpacity={`${onHover ? "1" : "0.05"}`}
        />
        <path
          d="M15 13.5H31.5"
          stroke={`${onHover ? "black" : "white"}`}
          strokeWidth="1.5"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
        <path
          d="M27.75 18L32.25 13.5L27.75 9"
          stroke={`${onHover ? "black" : "white"}`}
          strokeWidth="1.5"
          strokeLinecap="square"
        />
      </svg>
      {prev && (
        <div className="text-[14px] mt-3 md:mt-0 font-semibold leading-[19px] md:ml-[15px]">
          {prev}
        </div>
      )}
    </div>
  )
}

export const layoutQueryFragment = graphql`
  fragment TeamMembers on WpPage_Page_FlexibleContent {
    ... on WpPage_Page_FlexibleContent_TeamMembers {
      fieldGroupName
      ...coreCopyStackTeamMembers
      ...sectionTeamMembers
      teamMembers {
        heading
        teamMember {
          ... on WpTeamMember {
            id
            teamMember {
              name
              jobTitle
              description
              links {
                link {
                  title
                  url
                }
              }
              image {
                ...Image
              }
            }
          }
        }
      }
    }
  }
`
