import { graphql, Link } from "gatsby"
import React from "react"
import {
  Section,
  SectionProps,
} from "~/components/FlexibleLayouts/elements/Section"
import {
  CoreCopyStack,
  CoreCopyStackProps,
} from "~/components/FlexibleLayouts/elements/Typography"
import Image from "~/components/Image"
import BlogPlay from "~/images/blog-play-icon.svg"

export interface EventsSectionProps {
  section: SectionProps
  coreCopyStack: CoreCopyStackProps
  pastEvents: any[]
  recentEvents: any[]
}

export const EventsSection = ({
  coreCopyStack,
  section,
  pastEvents,
  recentEvents,
}: EventsSectionProps) => (
  <Section {...section}>
    <div className="max-w-[475px]">
      <CoreCopyStack {...coreCopyStack} />
    </div>

    <div className="mt-[67px] flex flex-wrap -ml-6 mb-16 md:mb-[84px]">
      {recentEvents?.map((recentEvent, i) => (
        <div key={`past${i}`} className="w-full pl-6 mb-10 md:w-1/3 md:mb-0">
          <EventPreview {...recentEvent} />
        </div>
      ))}
    </div>

    <h4 className="text-[16px] leading-[1.439] font-bold uppercase ">
      PAST EVENTS
    </h4>
    <div className="w-full bg-brown h-px mt-[10px] mb-[31px]"></div>

    <div className="mt-[67px] flex flex-wrap ml-[-17px] md:mb-[84px]">
      {pastEvents?.map((pastEvent, i) => (
        <div
          key={`recent${i}`}
          className="w-full pl-[17px] mb-10 md:w-1/2 lg:w-1/4  "
        >
          <EventPreview {...pastEvent} titleClass="text-[18px]" />
        </div>
      ))}
    </div>
  </Section>
)

export const EventPreview = ({
  link,
  title,
  date,
  eventType,
  uri,
  categories,
  featuredImage,
  titleClass = "text-[24px] ",
}) => (
  <Link to={uri} className="">
    {/* <div className="">
      <VideoModal
        blogPlay={BlogPlay}
        iconClass="w-[41px] h-[44px]"
        previewImage={image}
        channel={`custom`}
        customVideo={link}
      />
    </div> */}

    <div className={` overflow-hidden`}>
      <div className={`relative  aspect-h-3 md:aspect-h-2`}>
        <Image image={featuredImage?.node} objectFit={"cover"} />
        <div className="absolute inset-0 flex items-center justify-center cursor-pointer group">
          <img
            src={BlogPlay}
            draggable={false}
            className={`transition-transform duration-150 transform group-hover:scale-105 w-[41px] h-[44px]  `}
          />
        </div>
      </div>
    </div>
    <div className="mt-[41px]">
      <div className="flex uppercase text-brown text-[12px] items-center mb-[10px]">
        {eventType && (
          <div className="font-extrabold mr-[22px]">{categories[0]?.name}</div>
        )}
        <div>{date}</div>
      </div>
      <div className="mt-[10px]">
        <h5
          className={`font-bold leading-[1.30] text-slate normal-case ${titleClass}`}
        >
          {title}
        </h5>
      </div>
    </div>
  </Link>
)

export const layoutQueryFragment = graphql`
  fragment EventsSection on WpPage_Page_FlexibleContent {
    ... on WpPage_Page_FlexibleContent_EventsSection {
      fieldGroupName
      ...coreCopyStackEventsSection
      ...sectionEventsSection
      pastEvents {
        ... on WpPost {
          id
          title
          uri
          featuredImage {
            node {
              ...Image
            }
          }
          excerpt
          date(formatString: "MMMM d YYYY")
          categories {
            nodes {
              name
            }
          }
        }
      }
      recentEvents {
        ... on WpPost {
          id
          uri
          title
          featuredImage {
            node {
              ...Image
            }
          }
          excerpt
          date(formatString: "MMMM d YYYY")

          categories {
            nodes {
              name
            }
          }
        }
      }
    }
  }
`
