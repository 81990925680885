import { graphql } from "gatsby"
import React from "react"
import {
  Section,
  SectionProps,
} from "~/components/FlexibleLayouts/elements/Section"
import {
  BodyCopy,
  BodyCopySizeOption,
  CoreCopyStackProps,
  Heading,
  HeadingSizeOption,
  Subheading,
} from "~/components/FlexibleLayouts/elements/Typography"
import Image from "~/components/Image"

export interface SustainabilityProps {
  coreCopyStack: CoreCopyStackProps
  section: SectionProps
  iconLeft: string
  iconRight: string
}

export const Sustainability = ({
  coreCopyStack,
  section,
  iconLeft,
  iconRight,
}: SustainabilityProps) => (
  <Section {...section} noContainer paddingOverwrite="!pb-0  xl:!pb-[180px]">
    <div className="max-w-[1321px] bg-slate relative z-0 mx-auto">
      <div className="text-center max-w-[698px] px-5 py-20 md:py-[175px] mx-auto  text-white relative">
        <Subheading> {coreCopyStack?.subheading} </Subheading>

        <div className="mt-8">
          <Heading size={HeadingSizeOption.Medium}>
            {coreCopyStack?.heading}
          </Heading>
        </div>

        <div className="mt-[25px] max-w-[573px] mx-auto ">
          <BodyCopy size={BodyCopySizeOption.Small}>
            {coreCopyStack?.text}
          </BodyCopy>
        </div>
      </div>

      <div className="absolute hidden md:block  bottom-[4rem] left-[6rem] z-10 max-w-[114px]">
        <Image image={iconLeft} className="w-full h-full" />
      </div>
      <div className="absolute hidden md:block   bottom-[3rem] right-[6rem] z-10 max-w-[114px]">
        <Image image={iconRight} className="w-full h-full" />
      </div>
    </div>
  </Section>
)

export const layoutQueryFragment = graphql`
  fragment Sustainability on WpPage_Page_FlexibleContent {
    ... on WpPage_Page_FlexibleContent_Sustainability {
      fieldGroupName
      ...coreCopyStackSustainability
      ...sectionSustainability
      iconLeft {
        ...Image
      }
      iconRight {
        ...Image
      }
    }
  }
`
