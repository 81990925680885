import { motion, useAnimation } from "framer-motion"
import { graphql } from "gatsby"
import React, { useEffect } from "react"
import { useInView } from "react-intersection-observer"
import { LeftOrRight } from "~/components/FlexibleLayouts/elements/helpers"
import { Media } from "~/components/FlexibleLayouts/elements/Media"
import {
  Section,
  SectionProps,
} from "~/components/FlexibleLayouts/elements/Section"
import {
  CoreCopyStack,
  CoreCopyStackProps,
} from "~/components/FlexibleLayouts/elements/Typography"

export interface TextAndMediaColumnsContainedProps {
  section: SectionProps
  coreCopyStack: CoreCopyStackProps
  media: MediaProps
  mediaPosition: LeftOrRight
}

export const TextAndMediaColumnsContained = ({
  coreCopyStack,
  media,
  mediaPosition,
  section,
}: TextAndMediaColumnsContainedProps) => {
  const controls = useAnimation()
  const { ref, inView } = useInView()

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    } else {
      controls.start("hidden")
    }
  }, [controls, inView])

  return (
    <Section {...section}>
      <div
        ref={ref}
        className={`md:flex items-center ${
          mediaPosition === LeftOrRight.Left ? "flex-row-reverse" : ""
        }`}
      >
        <motion.div
          animate={controls}
          initial="hidden"
          variants={{
            hidden: { opacity: 0, y: 100 },
            visible: {
              opacity: 1,
              y: 0,
              transition: {
                duration: 1,
                delay: 0.3,
              },
            },
          }}
          className={`flex-1 mb-6 md:mb-0 `}
        >
          <div
            className={`md:max-w-[508px] md:py-[40px] ${
              mediaPosition === LeftOrRight.Left
                ? "md:mx-auto md:pl-[40px]"
                : "md:mx-auto md:pr-[40px]"
            }`}
          >
            <CoreCopyStack {...coreCopyStack} />
          </div>
        </motion.div>
        <motion.div
          animate={controls}
          initial="hidden"
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: {
              opacity: 1,
              y: 0,
              transition: {
                duration: 1,
                delay: 0.9,
              },
            },
          }}
          className="flex-1"
        >
          <div className={`relative `}>
            <Media {...media} />
          </div>
        </motion.div>
      </div>
    </Section>
  )
}

export const layoutQueryFragment = graphql`
  fragment TextAndMediaColumnsContained on WpPage_Page_FlexibleContent {
    ... on WpPage_Page_FlexibleContent_TextAndMediaColumnsContained {
      fieldGroupName
      ...mediaTextAndMediaColumnsContained
      ...coreCopyStackTextAndMediaColumnsContained
      ...sectionTextAndMediaColumnsContained
      mediaPosition
    }
  }
`
