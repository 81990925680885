import { graphql } from "gatsby"
import React from "react"
import { Card } from "~/components/FlexibleLayouts/elements/Card"
import {
  Section,
  SectionProps,
} from "~/components/FlexibleLayouts/elements/Section"
import {
  BodyCopySizeOption,
  CoreCopyStack,
  SecondaryCopyStackProps,
} from "~/components/FlexibleLayouts/elements/Typography"

export interface StaggeredTypographyCardsProps {
  coreCopyStack: CoreCopyStackProps
  section: SectionProps
  staggeredTypographyCards: {
    secondaryCopyStack: SecondaryCopyStackProps[]
  }
}

export const StaggeredTypographyCards = ({
  coreCopyStack,
  section,
  staggeredCards,
}: StaggeredTypographyCardsProps) => (
  <Section {...section}>
    <div className="max-w-3xl mx-auto text-center">
      <CoreCopyStack bodySize={BodyCopySizeOption.Small} {...coreCopyStack} />
    </div>

    <div className="flex w-full lg:-ml-[31px] mt-12 md:mt-[119px] flex-wrap">
      <div className="w-full lg:w-1/2 lg:pl-[31px] space-y-[31px] h-full">
        {staggeredCards?.slice(0, 2)?.map((card, i) => (
          <div key={`cardLeft${i}`} className={`w-full `}>
            <Card
              card={card}
              scrollText={card?.scrolltext}
              bodySize={BodyCopySizeOption.Medium}
              headingClass="!text-[32px] !leading-[1.30]"
            />
          </div>
        ))}
      </div>
      <div className="w-full lg:w-1/2 lg:pl-[31px] mt-6 lg:mt-[123px] space-y-[31px] h-full">
        {staggeredCards?.slice(2, 4)?.map((card, i) => (
          <div key={`cardRight${i}`} className={`w-full `}>
            <Card
              card={card}
              scrollText={card?.scrolltext}
              bodySize={BodyCopySizeOption.Medium}
              headingClass="!text-[32px] !leading-[1.30]"
            />
          </div>
        ))}
      </div>
    </div>
  </Section>
)

export const layoutQueryFragment = graphql`
  fragment StaggeredTypographyCards on WpPage_Page_FlexibleContent {
    ... on WpPage_Page_FlexibleContent_StaggeredTypographyCards {
      fieldGroupName
      ...coreCopyStackStaggeredTypographyCards
      ...sectionStaggeredTypographyCards
      staggeredCards {
        scrolltext
        image {
          ...Image
        }
        ...coreCopyStackStaggeredTypographyCardsStaggeredCards
        icon {
          ...Image
        }
      }
    }
  }
`
