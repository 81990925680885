import { graphql } from "gatsby"
import React from "react"
import { Links } from "~/components/FlexibleLayouts/elements/Link"
import {
  Section,
  SectionProps,
} from "~/components/FlexibleLayouts/elements/Section"
import {
  Heading,
  HeadingSizeOption,
  Subheading,
} from "~/components/FlexibleLayouts/elements/Typography"
import Image from "~/components/Image"

export interface CareersHeroProps {
  coreCopyStack: CoreCopyStackProps
  media: MediaProps
  section: SectionProps
}

export const CareersHero = ({
  coreCopyStack,
  section,
  image,
  intro,
}: CareersHeroProps) => (
  <Section {...section} noContainer paddingOverwrite="!pb-0 relative">
    <div className="relative w-full">
      <div className="absolute bottom-0 left-0 z-0 w-full lg:block bg-light-grey h-36 md:h-96 "></div>
      <div className="container">
        <Subheading>{coreCopyStack.subheading} </Subheading>

        <div className="md:max-w-[63rem] mt-5 ">
          <Heading Type="h1" size={HeadingSizeOption.XLarge}>
            {coreCopyStack.heading}
          </Heading>
        </div>
        <div className="relative flex mt-6 md:mt-12">
          {coreCopyStack.links && <Links links={coreCopyStack.links} />}
        </div>
        <div className="z-10 mt-24">
          <Image image={image} loading="eager" />
        </div>
      </div>
    </div>

    <div className="py-20 md:pb-[180px] md:pt-[168px] bg-light-grey ">
      <div className="container">
        <div className="relative flex flex-col items-center justify-center ">
          <Subheading>{intro.subheading} </Subheading>

          <div className="max-w-[855px] w-full text-black text-center mt-[25px] !leading-[1.184]">
            <Heading Type="h2">{intro.heading}</Heading>
          </div>
        </div>
      </div>
    </div>
  </Section>
)

export const layoutQueryFragment = graphql`
  fragment CareersHero on WpPage_Page_FlexibleContent {
    ... on WpPage_Page_FlexibleContent_CareersHero {
      fieldGroupName
      ...coreCopyStackCareersHero
      ...sectionCareersHero
      image {
        ...Image
      }
      intro {
        subheading
        heading
      }
    }
  }
`
