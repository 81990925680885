import { graphql } from "gatsby"
import React from "react"
import Marquee from "react-fast-marquee"
import {
  Section,
  SectionProps,
} from "~/components/FlexibleLayouts/elements/Section"

export interface ScrollingTextProps {
  section: SectionProps
}

export const ScrollingText = ({ text, section }: ScrollingTextProps) => (
  <div className="">
    <Section
      {...section}
      paddingOverwrite="border border-t border-b border-beige border-opacity-50 border-dashed"
    >
      <Marquee gradient={false} speed={100}>
        <h2 className="py-2 u-h2 text-[2rem] md:text-[2.5rem]">{text}</h2>
      </Marquee>
    </Section>
  </div>
)

export const layoutQueryFragment = graphql`
  fragment ScrollingText on WpPage_Page_FlexibleContent {
    ... on WpPage_Page_FlexibleContent_ScrollingText {
      fieldGroupName
      text
      ...sectionScrollingText
    }
  }
`
