import React, { Fragment } from "react"
import { graphql } from "gatsby"
import {
  Section,
  SectionProps,
} from "~/components/FlexibleLayouts/elements/Section"
import { TwoColumnGridWideSecond } from "~/components/FlexibleLayouts/elements/Grid"
import {
  Heading,
  CoreCopyStackProps,
} from "~/components/FlexibleLayouts/elements/Typography"
import { Links } from "../elements/Link"

export interface TwoColumnIntroProps {
  coreCopyStack: CoreCopyStackProps
  section: SectionProps
  heading: string
  text: string
  links: any
}

export const TwoColumnIntro = ({
  coreCopyStack,
  section,
  heading,
  text,
  links,
}: TwoColumnIntroProps) => (
  <Section {...section}>
    <TwoColumnGridWideSecond>
      <div>
        <Heading Type="h2">{heading}</Heading>
      </div>
      <div
        className="text-[17px] font-medium leading-[30px] "
        dangerouslySetInnerHTML={{ __html: text }}
      />

      <Links links={links} />
    </TwoColumnGridWideSecond>
  </Section>
)

export const layoutQueryFragment = graphql`
  fragment TwoColumnIntro on WpPage_Page_FlexibleContent {
    ... on WpPage_Page_FlexibleContent_TwoColumnIntro {
      fieldGroupName
      heading
      ...coreCopyStackTwoColumnIntro
      ...sectionTwoColumnIntro
      text
      links {
        videoEmbed
        email
        behaviour
        file {
          localFile {
            publicURL
          }
        }
        link {
          title
          url
        }
        type
        icon
      }
    }
  }
`
