import React, { Fragment } from "react"
import { graphql } from "gatsby"
import {
  Section,
  SectionProps,
} from "~/components/FlexibleLayouts/elements/Section"

export interface AnchorNavProps {
  section: SectionProps
  heading: string
  nav: any[]
}

export const AnchorNav = ({ heading, section, nav }: AnchorNavProps) => (
  <Section {...section} paddingOverwrite="!pb-0 !pt-[10px]">
    <div className="text-brown text-[17px] leading-[1.269] mb-[25px] font-semibold">
      {heading}
    </div>
    <div className="flex flex-wrap xl:flex-nowrap ml-[-5px]">
      {nav.map((navItem, i) => (
        <NavItem key={i} {...navItem} />
      ))}
    </div>
  </Section>
)

export const NavItem = ({ link, title }) => (
  <div className="flex items-center justify-center w-1/2 md:w-1/3 lg:w-1/5 pl-[5px] mb-[5px] xl:mb-0 flex-auto">
    <a
      href={link}
      className="flex items-center w-full justify-center py-[18px] px-[20px] bg-light-grey transition-colors duration-200 hover:bg-beige rounded-lg "
    >
      <svg
        className="flex-none"
        width="8"
        height="10"
        viewBox="0 0 8 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.02775 0V7.79439L0.872122 5.8036L0 6.61536L3.63636 10L7.27273 6.61536L6.40061 5.8036L4.26176 7.79439V0H3.02775Z"
          fill="#A71930"
        />
      </svg>

      <div className="ml-[11px] text-[12px] md:text-[15px] font-semibold leading-[18px]">
        {title}
      </div>
    </a>
  </div>
)

export const layoutQueryFragment = graphql`
  fragment AnchorNav on WpPage_Page_FlexibleContent {
    ... on WpPage_Page_FlexibleContent_AnchorNav {
      fieldGroupName
      ...sectionAnchorNav
      heading
      nav {
        title
        link
      }
    }
  }
`
