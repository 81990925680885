import { graphql } from "gatsby"
import React from "react"
import {
  Section,
  SectionProps,
} from "~/components/FlexibleLayouts/elements/Section"
import {
  CoreCopyStack,
  CoreCopyStackProps,
} from "~/components/FlexibleLayouts/elements/Typography"
import Image from "~/components/Image"

export interface LogoGridProps {
  coreCopyStack: CoreCopyStackProps
  section: SectionProps
  gallery: any[]
  footnote: string
}

export const LogoGrid = ({
  coreCopyStack,
  section,
  gallery,
  footnote,
}: LogoGridProps) => {
  return (
    <Section {...section}>
      <div className="max-w-[724px] mb-12">
        <CoreCopyStack {...coreCopyStack} />
      </div>
      <div className="grid items-center justify-center grid-cols-2 gap-6 md:grid-cols-3 lg:grid-cols-5 lg:gap-12">
        {gallery.map(image => (
          <LogoGridItem image={image} />
        ))}
      </div>
      {footnote && (
        <div className="text-[13px] text-black opacity-60 max-w-[500px] mt-[25px]">
          {footnote}
        </div>
      )}
    </Section>
  )
}

export const LogoGridItem = ({ image }) => (
  <div className="max-h-[50px]">
    <Image image={image} className="max-h-[50px]" />
  </div>
)

export const layoutQueryFragment = graphql`
  fragment LogoGrid on WpPage_Page_FlexibleContent {
    ... on WpPage_Page_FlexibleContent_LogoGrid {
      fieldGroupName
      ...coreCopyStackLogoGrid
      ...sectionLogoGrid
      gallery {
        ...Image
      }
      footnote
    }
  }
`
