import { AnimatePresence, motion, useAnimation } from "framer-motion"
import { graphql } from "gatsby"
import React, { useEffect, useState } from "react"
import { useInView } from "react-intersection-observer"
import ModalVideo from "react-modal-video"
import {
  TwoColumnGridAlternateVersion,
  TwoColumnGridWithAlternate,
} from "~/components/FlexibleLayouts/elements/Grid"
import {
  Section,
  SectionProps,
} from "~/components/FlexibleLayouts/elements/Section"
import {
  CoreCopyStack,
  CoreCopyStackProps,
  Heading,
  SecondaryCopyStack,
  SecondaryCopyStackProps,
} from "~/components/FlexibleLayouts/elements/Typography"
import Image from "~/components/Image"
import { Icon, IconType } from "../elements/Icons"
import { Link, LinkType } from "../elements/Link"

export interface TwoColumnGridListProps {
  coreCopyStack: CoreCopyStackProps
  section: SectionProps
  alternateVerison: TwoColumnGridAlternateVersion
  list: {
    secondaryCopyStack: SecondaryCopyStackProps[]
  }
  noContainer: boolean
  paddingOverwrite: string
  modalButton: string
  modal: any
}

export const TwoColumnGridList = ({
  coreCopyStack,
  section,
  list,
  alternateVersion,
  noContainer = false,
  paddingOverwrite,
  modalButton,
  modal,
}: TwoColumnGridListProps) => {
  const [showModal, setShowModal] = useState(false)

  const controls = useAnimation()
  const { ref, inView } = useInView()

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    } else {
      controls.start("hidden")
    }
  }, [controls, inView])

  return (
    <Section
      {...section}
      noContainer={noContainer}
      paddingOverwrite={paddingOverwrite}
    >
      <div ref={ref}>
        <TwoColumnGridWithAlternate
          className="sticky top-[7.5rem]"
          main={
            <div className="">
              <CoreCopyStack {...coreCopyStack} />
              {modalButton === "yes" && (
                <div
                  className="mt-6 cursor-pointer"
                  onClick={() => setShowModal(true)}
                >
                  <Link
                    link={{ title: "Learn More" }}
                    icon={IconType.ArrowRight}
                    type={LinkType.Button}
                  />
                </div>
              )}
            </div>
          }
          inner={list?.length > 0 && list.map((listItem, i) => (
            <TwoColumnGridListItem
              key={i}
              {...listItem}
              controls={controls}
              index={i}
            />
          ))}
          alternateVersion={alternateVersion}
        ></TwoColumnGridWithAlternate>
        <AnimatePresence>
          {showModal && <Modal {...modal} setShowModal={setShowModal} />}
        </AnimatePresence>

        <AnimatePresence>
          {showModal && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3, ease: "easeInOut" }}
              className="fixed top-0 bottom-0 left-0 right-0 bg-black bg-opacity-40"
              onClick={() => setShowModal(false)}
            ></motion.div>
          )}
        </AnimatePresence>
      </div>
    </Section>
  )
}

export const TwoColumnGridListItem = ({
  secondaryCopyStack,
  controls,
  index,
  video,
}: SecondaryCopyStackProps) => {
  const [isOpen, setOpen] = useState(false)

  const [videoID, setVideoID] = useState(false)

  useEffect(() => {
    if (!video?.videoEmbed) return
    let videoSplit
    if (video.videoEmbed.includes("v=")) {
      videoSplit = video.videoEmbed.split("v=")
    } else if (video.videoEmbed.includes("/embed/")) {
      videoSplit = video.videoEmbed.split("/embed/")
    }

    let videoID
    if (videoSplit && videoSplit?.length >= 2) {
      videoID = videoSplit[1]
      const ampersandPosition = videoID.indexOf("&")
      if (ampersandPosition !== -1) {
        videoID = videoID.substring(0, ampersandPosition)
      }
    }

    setVideoID(videoID)
  }, [video?.videoEmbed])

  return (
    <article className="md:max-w-[355px]">
      <motion.div
        animate={controls}
        initial="hidden"
        variants={{
          hidden: { opacity: 0, width: 0 },
          visible: {
            opacity: 1,
            width: "100%",
            transition: {
              duration: 0.5,
              delay: index * 0.5,
            },
          },
        }}
        className="h-[1px] bg-beige mb-[24px] lg:mb-[45px]"
      ></motion.div>
      <motion.div
        className="relative"
        animate={controls}
        initial="hidden"
        variants={{
          hidden: { opacity: 0, y: -25 },
          visible: {
            opacity: 1,
            y: 0,
            transition: {
              duration: 0.5,
              delay: index * 0.5 + 0.4,
            },
          },
        }}
      >
        <SecondaryCopyStack {...secondaryCopyStack} />

        {video?.videoEmbed && (
          <div
            className={`flex u-animate-button-arrow cursor-pointer items-center font-semibold text-[14px] mt-12 leading-[14px] md:text-[15px] md:leading-[15px] `}
            onClick={() => setOpen(true)}
          >
            {video?.title}

            <span
              className={`u-animate-target inline-block ml-2  relative top-[1px]`}
            >
              <Icon type={IconType.ArrowRight} />
            </span>
          </div>
        )}
      </motion.div>

      <ModalVideo
        channel={"youtube"}
        autoplay={1}
        isOpen={isOpen}
        videoId={videoID}
        onClose={() => setOpen(false)}
      />
    </article>
  )
}

export const Modal = ({ image, heading, content, setShowModal }) => {
  return (
    <motion.div
      key={`modal`}
      initial={{ x: "-100%" }}
      animate={{ x: "0%" }}
      exit={{ x: "-100%" }}
      transition={{ duration: 0.3, ease: "easeInOut" }}
      className="fixed top-0 left-0 bottom-0 z-[150] overflow-scroll bg-white scrollbar-hidden"
    >
      <div className=" px-6 md:px-[83px] py-[113px] relative">
        <div className="absolute top-[2rem] right-[2.5rem]">
          <svg
            onClick={() => setShowModal(false)}
            className="cursor-pointer"
            width="47"
            height="47"
            viewBox="0 0 47 47"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="23.5"
              cy="23.5"
              r="22.7295"
              stroke="#C4C4C4"
              strokeWidth="1.54098"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M44.4743 12.8903C40.5979 5.24209 32.6611 0 23.5 0V1.5C32.0379 1.5 39.4398 6.36357 43.0865 13.4713L44.4743 12.8903Z"
              fill="#948A85"
            />
            <path
              d="M17 18L29 30"
              stroke="#948A85"
              strokeWidth="1.5"
              strokeLinecap="square"
              strokeLinejoin="round"
            />
            <path
              d="M29 18L17 30"
              stroke="#948A85"
              strokeWidth="1.5"
              strokeLinecap="square"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <div className="max-w-[464px]">
          <Image image={image} />
        </div>

        <div className="max-w-[442px] mt-12 lg:mt-[104px]">
          <Heading>{heading}</Heading>
          <div
            className="mt-[20px] prose"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
      </div>
    </motion.div>
  )
}

export const layoutQueryFragment = graphql`
  fragment TwoColumnGridList on WpPage_Page_FlexibleContent {
    ... on WpPage_Page_FlexibleContent_TwoColumnGridList {
      fieldGroupName
      ...coreCopyStackTwoColumnGridList
      ...sectionTwoColumnGridList
      alternateVersion
      modalButton
      modal {
        heading
        content
        image {
          ...Image
        }
      }
      list {
        ...secondaryCopyStackTwoColumnGridList
        video {
          videoEmbed
          title
        }
      }
    }
  }
`
