import { AnimatePresence, motion } from "framer-motion"
import React, { Fragment, useRef, useState } from "react"
import { useEffect } from "react"
import Image from "./Image"

function BrandsModal({
  companyName,
  companyWebsite,
  content,
  enquiries,
  stats,
  statsToShow,
  brandStats,
  image,
  images,
  heading,
  logo,
  setShowModal,
  category,
  showModal,
  activeIndex,
}) {
  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "unset"
    }
  }, [showModal])

  const [activeItem, setActiveItem] = useState(0)
  const interval = useRef(null)

  if (!images) images = []

  useEffect(() => {
    interval.current = setInterval(() => {
      if (!images) return
      setActiveItem(i => (i + 1) % images.length)
    }, 5000)
    setActiveItem(0)
    return () => {
      clearInterval(interval.current)
    }
  }, [interval])

  return (
    <motion.div
      //   layoutId={`modal${activeIndex}`}
      key={`modal`}
      initial={{ x: "100%", opacity: 0 }}
      animate={{ x: "0%", opacity: 1 }}
      exit={{ x: "100%", opacity: 0 }}
      transition={{ duration: 0.5, ease: "easeInOut" }}
      className="fixed top-0 right-0 h-full overflow-scroll z-[150] w-[95%] lg:w-[912px] scrollbar-hidden"
    >
      <div className="w-full h-full bg-white">
        <div className="h-full overflow-scroll bg-white scrollbar-hidden">
          <div className="h-[427px] relative bg-black">
            <AnimatePresence>
              {images &&
                images.map(
                  (image, i) =>
                    activeItem === i && (
                      <motion.div
                        key={i}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        style={{
                          objectFit: "contain",
                          position: "absolute",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <Image
                          image={image.image}
                          objectFit="cover"
                          className="absolute w-full h-full image-absolute img-gradient"
                        />
                      </motion.div>
                    )
                )}
            </AnimatePresence>

            <div className="absolute top-0 bottom-0 left-0 w-full h-full ">
              <div className="flex flex-col justify-between h-full p-6 md:p-10 ">
                <div className="flex justify-end">
                  <svg
                    className="cursor-pointer"
                    onClick={() => setShowModal(false)}
                    width="47"
                    height="47"
                    viewBox="0 0 47 47"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="23.5"
                      cy="23.5"
                      r="22.7295"
                      stroke="white"
                      strokeOpacity="0.3"
                      strokeWidth="1.54098"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M44.4743 12.8903C40.5979 5.24209 32.6611 0 23.5 0V1.5C32.0379 1.5 39.4398 6.36357 43.0865 13.4713L44.4743 12.8903Z"
                      fill="white"
                    />
                    <path
                      d="M17 18L29 30"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="square"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M29 18L17 30"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="square"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>

                {/* <div className="space-x-2 space-y-2 font-semibold text-white uppercase md:pl-4 text-10">
                  {category.map((cat, i) => (
                    <div
                      key={`modalcat${i}`}
                      className="inline-block py-3 border border-white rounded-full px-9"
                    >
                      {cat?.name}
                    </div>
                  ))}
                </div> */}
              </div>
            </div>
          </div>

          <div className="flex flex-col items-center justify-center px-6 pt-12 pb-12 md:pt-20">
            <div className="max-w-[509px]">
              <Image
                className="max-w-[200px] max-h-[75px] object-contain object-left"
                image={logo}
              />

              <div className="mt-5 bg-black bg-opacity-50 h-[1px]"></div>

              <div className="mt-9">
                <div className="text-24 md:text-32 font-extrabold leading-[1.254] tracking-[-0.015em] uppercase ">
                  {heading}
                </div>

                <div
                  className="mt-8 font-light text-15 leading-[1.48] tracking-[-0.015em] text-[#717171] "
                  dangerouslySetInnerHTML={{ __html: content }}
                />
              </div>
            </div>
          </div>

          <div className="flex -ml-[0.313rem]  max-w-[655px] mx-auto flex-wrap px-6 ">
            {statsToShow.length > 0 &&
              statsToShow?.map((stat, i, arr) => (
                <div
                  key={`${stat?.stat}${i}`}
                  className={`md:pl-[0.313rem] w-full md:w-1/2 mt-[0.313rem] ${
                    i === arr.length - 1 && "mt-[0.313rem]"
                  }`}
                >
                  <div className="w-full py-10 text-white bg-black px-9 ">
                    <div className="font-semibold text-55 leading-[0.98] tracking-[-0.015em] mb-16">
                      {brandStats[stat]}
                    </div>

                    <p className="font-medium">{brandStats[stat + "Label"]}</p>
                  </div>
                </div>
              ))}
          </div>

          <div className="flex justify-center w-full pb-20">
            <div
              className={` flex flex-wrap items-center justify-center px-6 ${
                statsToShow.length > 0 && "py-20"
              }  -ml-6 font-semibold text-15 max-w-[575px]  w-full`}
            >
              {enquiries?.url && (
                <div className="w-full pl-6 md:w-1/2">
                  <a
                    target="_blank"
                    href={enquiries?.url || "#"}
                    className="w-full"
                  >
                    <div className="w-full py-6 text-center text-white transition-colors duration-300 ease-in-out bg-black border border-transparent rounded-full hover:bg-white hover:text-black hover:border-black">
                      {enquiries?.title || "Commercial Enquiries"}{" "}
                    </div>
                  </a>
                </div>
              )}

              {companyWebsite?.url && (
                <div className="w-full pl-6 mt-3 md:w-1/2 md:mt-0">
                  <a
                    target="_blank"
                    href={companyWebsite?.url || "#"}
                    className="w-full"
                  >
                    <div className="w-full py-6 text-center text-black transition-colors duration-300 ease-in-out bg-white border border-black rounded-full hover:bg-black hover:text-white hover:border-white">
                      {companyWebsite?.title || "Visit Website"}
                    </div>
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  )
}

export default BrandsModal
