import { motion, useAnimation } from "framer-motion"
import { graphql } from "gatsby"
import React, { useEffect } from "react"
import { useInView } from "react-intersection-observer"
import {
  TwoColumnGridAlternateVersion,
  TwoColumnGridWithAlternate,
} from "~/components/FlexibleLayouts/elements/Grid"
import {
  Section,
  SectionProps,
} from "~/components/FlexibleLayouts/elements/Section"
import {
  CoreCopyStack,
  CoreCopyStackProps,
  SecondaryCopyStackProps,
} from "~/components/FlexibleLayouts/elements/Typography"

export interface TwoColumnStatsGridProps {
  coreCopyStack: CoreCopyStackProps
  section: SectionProps
  alternateVerison: TwoColumnGridAlternateVersion
  list: TwoColumnStatsGridItemProps[]
}

export const TwoColumnStatsGrid = ({
  coreCopyStack,
  section,
  list,
  alternateVersion,
}: TwoColumnStatsGridProps) => {
  const controls = useAnimation()
  const { ref, inView } = useInView()

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    } else {
      controls.start("hidden")
    }
  }, [controls, inView])

  return (
    <Section {...section}>
      <div ref={ref}>
        <TwoColumnGridWithAlternate
          className=""
          main={<CoreCopyStack {...coreCopyStack} />}
          inner={list?.length > 0 && list?.map((listItem, i) => (
            <TwoColumnStatsGridItem
              controls={controls}
              index={i}
              key={i}
              {...listItem}
            />
          ))}
          alternateVersion={alternateVersion}
        ></TwoColumnGridWithAlternate>
      </div>
    </Section>
  )
}

export interface TwoColumnStatsGridItemProps {
  stat: string
  text: string
}

export const TwoColumnStatsGridItem = ({
  stat,
  text,
  controls,
  index,
}: SecondaryCopyStackProps) => (
  <motion.article
    animate={controls}
    initial="hidden"
    variants={{
      hidden: { opacity: 0, x: -25 },
      visible: {
        opacity: 1,
        x: 0,
        transition: {
          duration: 0.8,
          delay: index * 0.3,
        },
      },
    }}
    className="md:max-w-[260px] mb-8  md:mb-0"
  >
    <h6 className="text-[42px] leading-[42px] lg:text-[60px] lg:leading-[60px] xl:text-[80px] xl:leading-[80px] u-stroke-text font-semibold">
      {stat}
    </h6>
    <motion.div
      animate={controls}
      initial="hidden"
      variants={{
        hidden: { opacity: 0, width: 0 },
        visible: {
          opacity: 1,
          width: "100%",
          transition: {
            duration: 0.6,
            delay: index * 0.3 + 0.4,
          },
        },
      }}
      className="h-[1px] bg-beige mt-[10px] md:mt-[30px]"
    ></motion.div>
    <div className="text-[15px] leading-[19px]  pt-[5px] md:pt-[30px] mt-[10px] font-semibold">
      {text}
    </div>
  </motion.article>
)

export const layoutQueryFragment = graphql`
  fragment TwoColumnStatsGrid on WpPage_Page_FlexibleContent {
    ... on WpPage_Page_FlexibleContent_TwoColumnStatsGrid {
      fieldGroupName
      ...coreCopyStackTwoColumnStatsGrid
      ...sectionTwoColumnStatsGrid
      alternateVersion
      list {
        stat
        text
      }
    }
  }
`
