import { motion, useAnimation } from "framer-motion"
import { graphql } from "gatsby"
import React, { useEffect } from "react"
import { useInView } from "react-intersection-observer"
import { LeftOrRight } from "~/components/FlexibleLayouts/elements/helpers"
import { Media } from "~/components/FlexibleLayouts/elements/Media"
import {
  Section,
  SectionProps,
} from "~/components/FlexibleLayouts/elements/Section"
import {
  CoreCopyStack,
  CoreCopyStackProps,
} from "~/components/FlexibleLayouts/elements/Typography"
import Image from "~/components/Image"

export interface TextAndMediaColumnsProps {
  section: SectionProps
  coreCopyStack: CoreCopyStackProps
  media: MediaProps
  mediaPosition: LeftOrRight
  logos: any
}

export const TextAndMediaColumns = ({
  coreCopyStack,
  media,
  mediaPosition,
  section,
  logos,
}: TextAndMediaColumnsProps) => {
  const controls = useAnimation()
  const { ref, inView } = useInView()

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    } else {
      controls.start("hidden")
    }
  }, [controls, inView])

  return (
    <Section {...section}>
      <div
        ref={ref}
        className={`md:flex items-center ${
          mediaPosition === LeftOrRight.Left ? "flex-row-reverse" : ""
        }`}
      >
        <motion.div
          animate={controls}
          initial="hidden"
          variants={{
            hidden: { opacity: 0, y: 100 },
            visible: {
              opacity: 1,
              y: 0,
              transition: {
                duration: 1,
                delay: 0.3,
              },
            },
          }}
          className="flex-1 mb-6 md:mb-0"
        >
          <div
            className={`max-w-[430px] md:py-[40px] ${
              mediaPosition === LeftOrRight.Left
                ? "md:mx-auto lg:pl-[40px] lg:pt-[150px]"
                : "lg:pb-[150px]"
            }`}
          >
            <CoreCopyStack {...coreCopyStack} />
            {logos && (
              <div className="flex mt-6 md:space-x-5">
                {logos?.map((logo, i) => (
                  <div
                    className="max-h-[75px] gatsby-image-auto "
                    key={`logo${i}`}
                  >
                    <Image image={logo?.logo} className="max-h-[75px]" />
                  </div>
                ))}
              </div>
            )}
          </div>
        </motion.div>
        <motion.div
          animate={controls}
          initial="hidden"
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: {
              opacity: 1,
              y: 0,
              transition: {
                duration: 1,
                delay: 0.9,
              },
            },
          }}
          className="flex-1"
        >
          <div
            className={`relative ${
              mediaPosition === LeftOrRight.Left
                ? "md:left-[-35%] md:w-[120%] lg:left-[-25%] xl:left-[-21.5%]"
                : "md:left-[20%] md:w-[120%] lg:left-[10%] xl:left-[6.5%]"
            }`}
          >
            <Media {...media} />
          </div>
        </motion.div>
      </div>
    </Section>
  )
}

export const layoutQueryFragment = graphql`
  fragment TextAndMediaColumns on WpPage_Page_FlexibleContent {
    ... on WpPage_Page_FlexibleContent_TextAndMediaColumns {
      fieldGroupName
      ...mediaTextAndMediaColumns
      ...coreCopyStackTextAndMediaColumns
      ...sectionTextAndMediaColumns
      mediaPosition
      logos {
        logo {
          ...Image
        }
      }
    }
  }
`
