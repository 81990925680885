import { graphql } from "gatsby"
import React from "react"
import {
  Section,
  SectionProps,
} from "~/components/FlexibleLayouts/elements/Section"
import {
  BodyCopy,
  BodyCopySizeOption,
  CoreCopyStack,
  CoreCopyStackProps,
  SecondaryCopyStackProps,
  SecondaryHeading,
} from "~/components/FlexibleLayouts/elements/Typography"
import { Link } from "../elements/Link"

export interface NumberedCardsProps {
  coreCopyStack: CoreCopyStackProps
  section: SectionProps
  cards: {
    secondaryCopyStack: SecondaryCopyStackProps[]
  }
}

export const NumberedCards = ({
  coreCopyStack,
  section,
  cards,
}: NumberedCardsProps) => (
  <Section {...section}>
    <div className="text-center max-w-[827px] mx-auto">
      <CoreCopyStack {...coreCopyStack} />
    </div>
    {cards && (
      <div className="mt-[102px]">
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
          {cards?.map((card, i) => (
            <div
              key={i}
              className="w-full text-center text-white bg-slate px-6  md:px-[50px] py-[75px]"
            >
              {card?.number && (
                <div className="text-[66px] font-mystique">{card?.number}</div>
              )}
              <div className="mb-[15px]">
                <SecondaryHeading>{card?.heading} </SecondaryHeading>
              </div>
              <BodyCopy size={BodyCopySizeOption.Small}>
                {card?.content}{" "}
              </BodyCopy>

              {card?.link && (
                <div className="mt-6">
                  <Link
                    icon={card?.icon}
                    behaviour={card?.behaviour}
                    videoEmbed={card?.videoEmbed}
                    type={card?.type}
                    link={card?.link}
                    file={card?.file}
                    email={card?.email}
                  />
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    )}
  </Section>
)

export const layoutQueryFragment = graphql`
  fragment NumberedCards on WpPage_Page_FlexibleContent {
    ... on WpPage_Page_FlexibleContent_NumberedCards {
      fieldGroupName
      ...coreCopyStackNumberedCards
      ...sectionNumberedCards
      cards {
        videoEmbed
        type
        link {
          url
          title
        }
        icon
        behaviour
        file {
          localFile {
            publicURL
          }
        }
        email
        content
        heading
        number
      }
    }
  }
`
