import { motion, useAnimation } from "framer-motion"
import { graphql, Link as GLink } from "gatsby"
import React, { useEffect, useState } from "react"
import { useInView } from "react-intersection-observer"
import { useWindowSize } from "react-use"
import "swiper/components/scrollbar/scrollbar.scss"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper.scss"
import { Icon, IconType } from "~/components/FlexibleLayouts/elements/Icons"
import { Link } from "~/components/FlexibleLayouts/elements/Link"
import {
  Section,
  SectionProps,
} from "~/components/FlexibleLayouts/elements/Section"
import {
  BodyCopy,
  BodyCopySizeOption,
  CoreCopyStack,
  Heading,
  HeadingSizeOption,
} from "~/components/FlexibleLayouts/elements/Typography"
import Image from "~/components/Image"

export interface FeaturedIconCardsProps {
  coreCopyStack: CoreCopyStackProps
  cards: IconCardProps[]
  section: SectionProps
  logos: any
}

export const FeaturedIconCards = ({
  coreCopyStack,
  cards,
  logos,
  section,
}: FeaturedIconCardsProps) => {
  const controls = useAnimation()
  const { ref, inView } = useInView()

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    } else {
      controls.start("hidden")
    }
  }, [controls, inView])

  const { width } = useWindowSize()

  return (
    <Section {...section} paddingOverwrite="overflow-hidden">
      <div className="mb-[30px] lg:mb-[50px] xl:mb-[70px] max-w-[1000px]">
        <CoreCopyStack {...coreCopyStack} />
        {logos && (
          <div className="flex mt-6 md:space-x-5">
            {logos?.map((logo, i) => (
              <div
                className="max-h-[75px] gatsby-image-auto w-full h-full "
                key={`logo${i}`}
              >
                <Image image={logo?.logo} className="max-h-[75px]" />
              </div>
            ))}
          </div>
        )}
      </div>
      <div ref={ref}>
        <Swiper
          className="flex flex-col w-full"
          slidesPerView={width < 476 ? 1.1 : `auto`}
          style={{
            overflow: `visible`,
            cursor: cards?.length < 4 ? "default" : "grab",
          }}
        >
          {cards.map((card, i) => (
            <SwiperSlide
              key={`cardSlider${i}`}
              className=" bg-white last:mr-0  max-w-[384px] !h-auto mr-7  "
            >
              <IconCard controls={controls} index={i} key={i} {...card} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </Section>
  )
}

export interface IconCardProps {
  icon: IconType
  heading: string
  text: string
  link: LinkProps
}

export const IconCard = ({
  icon,
  heading,
  text,
  link,
  controls,
  index,
}: IconCardProps) => {
  const [onHover, setOnHover] = useState(false)

  return (
    <GLink to={link?.url}>
      <motion.div
        animate={controls}
        initial="hidden"
        variants={{
          hidden: { opacity: 0, y: 50 },
          visible: {
            opacity: 1,
            y: 0,
            transition: {
              duration: 0.5,
              delay: index * 0.3,
            },
          },
        }}
        onMouseEnter={() => setOnHover(true)}
        onMouseLeave={() => setOnHover(false)}
        className="min-h-[100%] flex flex-col bg-light-grey px-[32px] py-[40px] lg:px-[48px] lg:py-[60px] "
      >
        <motion.div
          animate={controls}
          initial="hidden"
          variants={{
            hidden: { opacity: 0, y: -20 },
            visible: {
              opacity: 1,
              y: 0,
              transition: {
                duration: 0.5,
                delay: index * 0.3 + 0.2,
              },
            },
          }}
          className="h-[100px] md:h-[160px]"
        >
          <Icon type={icon} />
        </motion.div>
        <div className="max-w-[264px] min-h-[60px] pb-6">
          <Heading size={HeadingSizeOption.Small}>{heading}</Heading>
        </div>
        <BodyCopy size={BodyCopySizeOption.Small}>{text}</BodyCopy>
        {link.link && (
          <div
            className={`pt-4 mt-auto transition-opacity duration-200 ${
              onHover ? "opacity-100" : "opacity-0"
            }`}
          >
            <Link {...link} />
          </div>
        )}
      </motion.div>
    </GLink>
  )
}

export const layoutQueryFragment = graphql`
  fragment FeaturedIconCards on WpPage_Page_FlexibleContent {
    ... on WpPage_Page_FlexibleContent_FeaturedIconCards {
      fieldGroupName
      ...coreCopyStackFeaturedIconCards
      ...sectionFeaturedIconCards
      cards {
        icon
        heading
        text
        link {
          link {
            title
            url
          }
          type
          icon
        }
      }
      logos {
        logo {
          ...Image
        }
      }
    }
  }
`
