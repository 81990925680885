import { AnimatePresence, motion } from "framer-motion"
import { graphql } from "gatsby"
import React, { Fragment, useState } from "react"
import {
  Section,
  SectionProps,
} from "~/components/FlexibleLayouts/elements/Section"
import {
  CoreCopyStackProps,
  Heading,
  Subheading,
} from "~/components/FlexibleLayouts/elements/Typography"

export interface ListSwitcherProps {
  coreCopyStack: CoreCopyStackProps
  section: SectionProps
  listGroup: any[]
}

export const ListSwitcher = ({
  coreCopyStack,
  section,
  listGroup,
}: ListSwitcherProps) => {
  const [checked, setChecked] = useState(0)

  return (
    <Section {...section}>
      <div className="relative ">
        <div className="flex flex-wrap lg:flex-nowrap">
          <div className="w-full lg:w-[40%]">
            <div className="lg:max-w-[25.75rem] sticky top-[7.5rem]">
              <Subheading>{coreCopyStack?.subheading} </Subheading>
              <div className="mt-5">
                <Heading> {coreCopyStack?.heading}</Heading>
              </div>
              <div className="mt-12 md:mt-[76px] font-bold text-[20px] leading-[2.469] flex space-x-6 lg:space-x-0 lg:block ">
                <div className="">
                  <div
                    className={`cursor-pointer transition-colors duration-200 ease-in-out ${
                      checked === 0
                        ? " text-white"
                        : " text-brown hover:text-white"
                    } `}
                    onClick={() => setChecked(0)}
                  >
                    {listGroup[0]?.label}
                  </div>
                </div>
                <div className="">
                  <div
                    className={`cursor-pointer transition-colors duration-200 ease-in-out ${
                      checked === 1
                        ? "text-white"
                        : " text-brown hover:text-white"
                    } `}
                    onClick={() => setChecked(1)}
                  >
                    {listGroup[1]?.label}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12 lg:ml-[266px] lg:w-[60%] lg:mt-0">
            <div className="flex flex-wrap  xl:ml-[-7.75rem] text-[18px] leading-[1.5] font-bold">
              {listGroup?.map(
                (list, i) =>
                  checked === i && (
                    <Fragment key={`list${i}`}>
                      <div className="flex justify-between w-full uppercase    mb-[44px]">
                        <div>{list.leftHeading}</div>
                        <div>{list?.rightHeading}</div>
                      </div>
                      <AnimatePresence>
                        {checked === i &&
                          list?.rows.map((row, i) => <Rows {...row} i={i} />)}
                      </AnimatePresence>
                    </Fragment>
                  )
              )}
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export const Rows = ({ contentLeft, contentRight, i }) => (
  <motion.div
    key={`row${i}`}
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{
      duration: 0.7,
      delay: (i + 1) / 12,
    }}
    className="w-full"
  >
    <div className="flex justify-between w-full">
      <div className="text-brown">{contentLeft}</div>
      <div className="text-white">{contentRight}</div>
    </div>
    <div className="w-full h-px bg-beige my-[24px]"></div>
  </motion.div>
)

export const layoutQueryFragment = graphql`
  fragment ListSwitcher on WpPage_Page_FlexibleContent {
    ... on WpPage_Page_FlexibleContent_ListSwitcher {
      fieldGroupName
      ...coreCopyStackListSwitcher
      ...sectionListSwitcher
      listGroup {
        label
        leftHeading
        rightHeading
        rows {
          contentRight
          contentLeft
        }
      }
    }
  }
`
