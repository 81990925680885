import { graphql } from "gatsby"
import React from "react"
import { AnchorNav } from "~/components/FlexibleLayouts/layouts/AnchorNav"
import { Brands } from "~/components/FlexibleLayouts/layouts/Brands"
import { CareersHero } from "~/components/FlexibleLayouts/layouts/CareersHero"
import { Contacts } from "~/components/FlexibleLayouts/layouts/Contacts"
import { DefaultHero } from "~/components/FlexibleLayouts/layouts/DefaultHero"
import { DownloadGrid } from "~/components/FlexibleLayouts/layouts/DownloadGrid"
import { DownloadList } from "~/components/FlexibleLayouts/layouts/DownloadList"
import { EventsSection } from "~/components/FlexibleLayouts/layouts/EventsSection"
import { ExperiencesSlider } from "~/components/FlexibleLayouts/layouts/ExperiencesSlider"
import { ExploreCta } from "~/components/FlexibleLayouts/layouts/ExploreCta"
import { FeaturedIconCards } from "~/components/FlexibleLayouts/layouts/FeaturedIconCards"
import { FeaturedNews } from "~/components/FlexibleLayouts/layouts/FeaturedNews"
import { FeaturedVideo } from "~/components/FlexibleLayouts/layouts/FeaturedVideo"
import { FullWidthCards } from "~/components/FlexibleLayouts/layouts/FullWidthCards"
import { FullWidthImage } from "~/components/FlexibleLayouts/layouts/FullWidthImage"
import { Highlights } from "~/components/FlexibleLayouts/layouts/Highlights"
import { HomeHero } from "~/components/FlexibleLayouts/layouts/HomeHero"
import { HubCta } from "~/components/FlexibleLayouts/layouts/HubCta"
import { JobListingSection } from "~/components/FlexibleLayouts/layouts/JobListings"
import { LegalHero } from "~/components/FlexibleLayouts/layouts/LegalHero"
import { ListSwitcher } from "~/components/FlexibleLayouts/layouts/ListSwitcher"
import { LogoGrid } from "~/components/FlexibleLayouts/layouts/LogoGrid"
import { Meetings } from "~/components/FlexibleLayouts/layouts/Meetings"
import { MidpageHero } from "~/components/FlexibleLayouts/layouts/MidpageHero"
import { FlexibleNewsletter } from "~/components/FlexibleLayouts/layouts/Newsletter"
import { NumberedCards } from "~/components/FlexibleLayouts/layouts/NumberedCards"
import { Offices } from "~/components/FlexibleLayouts/layouts/Offices"
import { ScrollingText } from "~/components/FlexibleLayouts/layouts/ScrollingText"
import { CardSlider } from "~/components/FlexibleLayouts/layouts/SliderCards"
import { SliderHistorySlider } from "~/components/FlexibleLayouts/layouts/SliderHistory"
import { PeopleSlider } from "~/components/FlexibleLayouts/layouts/SliderPeople"
import { StaggeredCards } from "~/components/FlexibleLayouts/layouts/StaggeredCards"
import { StaggeredTypographyCards } from "~/components/FlexibleLayouts/layouts/StaggeredTypographyCards"
import { Sustainability } from "~/components/FlexibleLayouts/layouts/Sustainability"
import { TabbedSection } from "~/components/FlexibleLayouts/layouts/TabbedSection"
import { TeamMembers } from "~/components/FlexibleLayouts/layouts/TeamMembers"
import { TextAndMediaColumns } from "~/components/FlexibleLayouts/layouts/TextAndMediaColumns"
import { TextAndMediaColumnsContained } from "~/components/FlexibleLayouts/layouts/TextAndMediaColumnsContained"
import { TextCardSection } from "~/components/FlexibleLayouts/layouts/TextCardSection"
import { TextCta } from "~/components/FlexibleLayouts/layouts/TextCta"
import { TextWSidebar } from "~/components/FlexibleLayouts/layouts/TextWSidebar"
import { TrackRecord } from "~/components/FlexibleLayouts/layouts/TrackRecord"
import { TwoColumnGridList } from "~/components/FlexibleLayouts/layouts/TwoColumnGridList"
import { TwoColumnIntro } from "~/components/FlexibleLayouts/layouts/TwoColumnIntro"
import { TwoColumnList } from "~/components/FlexibleLayouts/layouts/TwoColumnList"
import { NewsPosts } from "~/components/FlexibleLayouts/layouts/TwoColumnNewsPosts"
import { TwoColumnStatsGrid } from "~/components/FlexibleLayouts/layouts/TwoColumnStatsGrid"
import { TwoColumnStatsGridAlt } from "~/components/FlexibleLayouts/layouts/TwoColumnStatsGridAlt"
import { TwoColumnText } from "~/components/FlexibleLayouts/layouts/TwoColumnText"
import { VennDiagram } from "~/components/FlexibleLayouts/layouts/VennDiagram"

export const FlexibleLayout = props => {
  const { layoutName } = props
  switch (layoutName) {
    case "DefaultHero":
      return <DefaultHero {...props} />
    case "LegalHero":
      return <LegalHero {...props} />
    case "ExploreCta":
      return <ExploreCta {...props} />
    case "FeaturedIconCards":
      return <FeaturedIconCards {...props} />
    case "JobListings":
      return <JobListingSection {...props} />
    case "HubCta":
      return <HubCta {...props} />
    case "TextAndMediaColumns":
      return <TextAndMediaColumns {...props} />
    case "TextCta":
      return <TextCta {...props} />
    case "TwoColumnGridList":
      return <TwoColumnGridList {...props} />
    case "TwoColumnList":
      return <TwoColumnList {...props} />
    case "TwoColumnStatsGrid":
      return <TwoColumnStatsGrid {...props} />
    case "MidpageHero":
      return <MidpageHero {...props} />
    case "FeaturedVideo":
      return <FeaturedVideo {...props} />
    case "LogoGrid":
      return <LogoGrid {...props} />
    case "NumberedCards":
      return <NumberedCards {...props} />
    case "CardSlider":
      return <CardSlider {...props} />
    case "SliderHistorySlider":
      return <SliderHistorySlider {...props} />
    case "StaggeredCards":
      return <StaggeredCards {...props} />
    case "TextAndMediaColumnsContained":
      return <TextAndMediaColumnsContained {...props} />
    case "TeamMembers":
      return <TeamMembers {...props} />
    case "Offices":
      return <Offices {...props} />
    case "CareersHero":
      return <CareersHero {...props} />
    case "PeopleSlider":
      return <PeopleSlider {...props} />
    case "Sustainability":
      return <Sustainability {...props} />
    case "Contacts":
      return <Contacts {...props} />
    case "Highlights":
      return <Highlights {...props} />
    case "TrackRecord":
      return <TrackRecord {...props} />
    case "ListSwitcher":
      return <ListSwitcher {...props} />
    case "HomeHero":
      return <HomeHero {...props} />
    case "ScrollingText":
      return <ScrollingText {...props} />
    case "VennDiagram":
      return <VennDiagram {...props} />
    case "StaggeredTypographyCards":
      return <StaggeredTypographyCards {...props} />
    case "FeaturedNews":
      return <FeaturedNews {...props} />
    case "ExperiencesSlider":
      return <ExperiencesSlider {...props} />
    case "NewsPosts":
      return <NewsPosts {...props} />
    case "FullWidthCards":
      return <FullWidthCards {...props} />
    case "TextCardSection":
      return <TextCardSection {...props} />
    case "TwoColumnStatsGridAlt":
      return <TwoColumnStatsGridAlt {...props} />
    case "DownloadList":
      return <DownloadList {...props} />
    case "TabbedSection":
      return <TabbedSection {...props} />
    case "EventsSection":
      return <EventsSection {...props} />
    case "DownloadGrid":
      return <DownloadGrid {...props} />
    case "FlexibleNewsletter":
      return <FlexibleNewsletter {...props} />
    case "AnchorNav":
      return <AnchorNav {...props} />
    case "TextWSidebar":
      return <TextWSidebar {...props} />
    case "Brands":
      return <Brands {...props} />
    case "TwoColumnText":
      return <TwoColumnText {...props} />
    case "TwoColumnIntro":
      return <TwoColumnIntro {...props} />
    case "FullWidthImage":
      return <FullWidthImage {...props} />
    case "Meetings":
      return <Meetings {...props} />
  }
  console.log(`Flexible layout mapping not found: ${layoutName}`)
  return null
}

export const queryFragment = graphql`
  fragment FlexibleContent on WpPage_Page {
    flexibleContent {
      ...DefaultHero
      ...TwoColumnList
      ...FeaturedIconCards
      ...HubCta
      ...TwoColumnGridList
      ...TextAndMediaColumns
      ...ExploreCta
      ...TextCta
      ...TwoColumnStatsGrid
      ...MidpageHero
      ...FeaturedVideo
      ...LogoGrid
      ...NumberedCards
      ...SliderHistorySlider
      ...StaggeredCards
      ...TextAndMediaColumnsContained
      ...TeamMembers
      ...Offices
      ...CareersHero
      ...PeopleSlider
      ...Sustainability
      ...Contacts
      ...Highlights
      ...TrackRecord
      ...ListSwitcher
      ...HomeHero
      ...ScrollingText
      ...VennDiagram
      ...StaggeredTypographyCards
      ...FeaturedNews
      ...ExperiencesSlider
      ...NewsPosts
      ...FullWidthCards
      ...TextCardSection
      ...TwoColumnStatsGridAlt
      ...DownloadList
      ...EventsSection
      ...DownloadGrid
      ...FlexibleNewsletter
      ...AnchorNav
      ...TabbedSection
      ...TextWSidebar
      ...Brands
      ...TwoColumnText
      ...TwoColumnIntro
      ...Meetings
      ...CardSlider
      ...LegalHero
      ...JobListings
      ...FullWidthImage
    }
  }
`
