import { motion } from "framer-motion"
import React from "react"
import DownloadButton from "../buttons/DownloadButton"

function DownloadsGrid({ downloads, id, showDescription }) {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-y-[3.75rem] gap-x-[2.5rem]  mt-12  md:mt-20 ">
      {downloads?.map((download, i, arr) => (
        <motion.div
          key={`downloadGrid${download?.subheading}${i}`}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.7, delay: (i + 1) / 12 }}
        >
          <div className="flex flex-col max-w-[30.375rem]">
            {download?.subheading && (
              <div className="text-xs leading-[1.109] tracking-[0.05em] opacity-60">
                {download?.subheading}
              </div>
            )}
            <div className="mt-3 font-extrabold uppercase u-p2 text-19">
              {download?.heading}
            </div>
            {showDescription && (
              <p
                className="mt-3"
                dangerouslySetInnerHTML={{
                  __html: download?.content,
                }}
              />
            )}
            <DownloadButton
              file={
                download?.file ||
                download?.download?.file ||
                download?.downloads?.file
              }
              downloadText={download?.downloadText}
              className="mt-8"
            />
          </div>
        </motion.div>
      ))}
    </div>
  )
}

export default DownloadsGrid
