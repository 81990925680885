import React, { Fragment } from "react"
import { getReactChildAtIndex } from "~/components/FlexibleLayouts/elements/helpers"

export interface GridProps {
  children: React.ReactNode
}

export const OneColumnGrid = ({ children }: GridProps) => (
  <div className="space-y-[20px] md:space-y-[50px]">
    {React.Children.map(children, (child, i) => (
      <div key={i}>{child}</div>
    ))}
  </div>
)

export const TwoColumnGrid = ({ children }: GridProps) => (
  <div className="grid grid-cols-1 space-y-4 md:grid-cols-2 md:space-y-0">
    {React.Children.map(children, (child, i) => (
      <div key={i}>{child}</div>
    ))}
  </div>
)

export const TwoColumnInnerGrid = ({ children }: GridProps) => (
  <div className="grid grid-cols-1 lg:grid-cols-2">
    {React.Children.map(children, (child, i) => (
      <div
        className={`mb-8 lg:mb-0 md:pl-[40px] xl:pl-[80px] ${
          i > 1 ? "lg:mt-[80px]" : ""
        }`}
        key={i}
      >
        {child}
      </div>
    ))}
  </div>
)

export const TwoColumnGridWideSecond = ({ children }: GridProps) => (
  <div className="md:flex md:space-x-4 lg:space-x-6">
    <div className="relative mb-8 md:mb-0 md:w-1/2 lg:w-1/3">
      {getReactChildAtIndex(children, 0)}
    </div>
    <div className="md:w-1/2 lg:w-2/3">{getReactChildAtIndex(children, 1)}</div>
  </div>
)

export const ThreeColumnGrid = ({ children }: GridProps) => (
  <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 md:gap-y-20">
    {React.Children.map(children, (child, i) => (
      <div key={i}>{child}</div>
    ))}
  </div>
)

export const FourColumnGrid = ({ children }: GridProps) => (
  <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-4">
    {React.Children.map(children, (child, i) => (
      <div key={i}>{child}</div>
    ))}
  </div>
)

export enum TwoColumnGridAlternateVersion {
  None = "none",
  ThreeColumn = "three-column",
  FourColumn = "four-column",
}

export const TwoColumnGridWithAlternate = ({
  main,
  inner,
  alternateVersion,
  className = "max-w-[520px]",
}: GridProps) => {
  let GridLayout = TwoColumnGridWideSecond
  let InnerGridLayout = TwoColumnInnerGrid
  if (alternateVersion === TwoColumnGridAlternateVersion.ThreeColumn) {
    GridLayout = OneColumnGrid
    InnerGridLayout = ThreeColumnGrid
  }
  if (alternateVersion === TwoColumnGridAlternateVersion.FourColumn) {
    GridLayout = OneColumnGrid
    InnerGridLayout = FourColumnGrid
  }

  return (
    <GridLayout>
      <div className={`${className}`}>{main}</div>
      <Fragment>
        <InnerGridLayout>{inner}</InnerGridLayout>
      </Fragment>
    </GridLayout>
  )
}
