import { motion } from "framer-motion"
import { graphql } from "gatsby"
import React, { useState } from "react"
import {
  Section,
  SectionProps,
} from "~/components/FlexibleLayouts/elements/Section"
import {
  Heading,
  Subheading,
} from "~/components/FlexibleLayouts/elements/Typography"
import useJobs from "~/hooks/useJobs"
import { Icon } from "../elements/Icons"

export interface JobListingSectionProps {
  section: SectionProps
  heading: string
  subheading: string
  cantFind: any
  link: any
}

export const JobListingSection = ({
  heading,
  subheading,
  cantFind,
  section,
  link,
}: JobListingSectionProps) => {
  const { allWorkableJob } = useJobs()

  return (
    <Section {...section}>
      <div className="relative">
        <div className="max-w-[669px]">
          <Subheading>{subheading}</Subheading>
          <Heading className="mt-[25px mb-12 md:mb-20">{heading} </Heading>
        </div>

        {allWorkableJob?.nodes?.map((job, i) => (
          <React.Fragment key={`jobList${i}`}>
            <JobListing {...job} i={i} />
          </React.Fragment>
        ))}
        <div className="flex justify-center mt-10 md:justify-end">
          <a target="_blank" href={link?.url}>
            <span
              className={`bg-slate hover:bg-white transition-colors text-white hover:text-slate py-[18px] px-[28px] md:py-[24px] md:px-[34px] rounded-[30px]`}
            >
              {link?.title}
              <span className={`u-animate-target inline-block ml-2 relative`}>
                <Icon type="arrow-right" />
              </span>
            </span>
          </a>
        </div>

        <div className="relative flex flex-col items-center justify-between mt-20 text-center bg-white md:text-left p-11 md:flex-row">
          <div>
            <h5 className="u-h5b leading-[1.2]">{cantFind?.heading}</h5>
            <div
              className={`mt-6 lg:mt-0 `}
              dangerouslySetInnerHTML={{ __html: cantFind?.content }}
            />
          </div>
          <div className="flex items-center mt-8 md:mt-0">
            <a
              className="text-15 font-semibold inline-flex items-center transition-opacity duration-200 ease-in-out hover:opacity-50"
              href={`mailto:${cantFind?.email}`}
              target="_blank"
            >
              <span className="mr-1.5">{cantFind?.email}</span>

              <svg
                width="13"
                height="11"
                viewBox="0 0 13 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 4.5957L10.9673 4.5957V6.13822L0 6.13822L0 4.5957Z"
                  fill="#231F20"
                />
                <path
                  d="M7.88363 10.5352L6.89258 9.45483L10.6432 5.36674L6.89258 1.27865L7.88363 0.198242L12.624 5.36676L7.88363 10.5352Z"
                  fill="#231F20"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </Section>
  )
}

const JobListing = ({ title, contract, location, url, i }) => {
  const [onHover, setOnHover] = useState(false)

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.7, delay: (i + 1) / 12 }}
      onMouseEnter={() => setOnHover(true)}
      onMouseLeave={() => setOnHover(false)}
    >
      <a
        target="_blank"
        className="flex flex-wrap items-center justify-between p-8 bg-beige md:flex-nowrap mb-2.5 cursor-pointer"
        href={url}
      >
        <h5 className="w-full u-h5b leading-[1.2] md:leading-[1.569]">
          {title}
        </h5>

        <div className="flex items-center justify-between w-full max-w-[28rem] mt-2 md:mt-0">
          <div className="flex items-center">
            <svg
              className="hidden md:block"
              width="7"
              height="10"
              viewBox="0 0 7 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.49973 0.1368C1.56665 0.1368 0 1.60952 0 3.4267C0 5.24388 2.98922 8.86757 2.98922 8.86757C3.2711 9.226 3.73125 9.22731 4.01174 8.86625C4.01174 8.86625 7 5.24335 7 3.42662C6.99953 1.60944 5.43287 0.136719 3.4998 0.136719L3.49973 0.1368ZM3.49973 5.03633C2.55374 5.03633 1.78696 4.31553 1.78696 3.42625C1.78696 2.53698 2.55374 1.81617 3.49973 1.81617C4.44571 1.81617 5.21249 2.53698 5.21249 3.42625C5.21249 4.31553 4.44571 5.03633 3.49973 5.03633Z"
                fill="black"
              />
            </svg>

            <p className="text-14 md:ml-1.5 font-semibold leading-[1.2] mt-2 md:mt-0 mr-[10px]">
              {location.location_str}
            </p>
          </div>
          {contract && <p className="uppercase text-[0.625rem]">{contract}</p>}

          <svg
            className="flex-none"
            width="56"
            height="32"
            viewBox="0 0 56 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              y="0.136719"
              width="55.25"
              height="31.5714"
              rx="15.7857"
              fill={` ${onHover ? "#A71930" : "black"} `}
            />
            <path
              d="M17.5391 15.9219H36.8327"
              stroke="white"
              strokeWidth="1.75397"
              strokeLinecap="square"
              strokeLinejoin="round"
            />
            <path
              d="M32.4473 21.184L37.7092 15.9221L32.4473 10.6602"
              stroke="white"
              strokeWidth="1.75397"
              strokeLinecap="square"
            />
          </svg>
        </div>
      </a>
    </motion.div>
  )
}
export const layoutQueryFragment = graphql`
  fragment JobListings on WpPage_Page_FlexibleContent {
    ... on WpPage_Page_FlexibleContent_JobListings {
      fieldGroupName
      ...sectionJobListings
      heading
      subheading
      link {
        url
        title
      }
      cantFind {
        heading
        content
        email
      }
    }
  }
`
