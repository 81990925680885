import React from "react"
import { graphql } from "gatsby"
import {
  Section,
  SectionProps,
} from "~/components/FlexibleLayouts/elements/Section"
import {
  CoreCopyStack,
  CoreCopyStackProps,
  HeadingSizeOption,
} from "~/components/FlexibleLayouts/elements/Typography"
import ShareholderTable from "~/components/tables/ShareholderTable"

export interface TwoColumnTextProps {
  coreCopyStack: CoreCopyStackProps
  section: SectionProps
  contentLeft: string
  contentRight: string
  table: any
  bottomText: string
}

export const TwoColumnText = ({
  coreCopyStack,
  section,
  contentLeft,
  contentRight,
  table,
  bottomText,
}: TwoColumnTextProps) => (
  <Section {...section}>
    <div className="max-w-md mb-10">
      <CoreCopyStack headingSize={HeadingSizeOption.Large} {...coreCopyStack} />
    </div>

    <div className="md:flex md:space-x-5 lg:space-x-[83px] font-medium text-[15px] md:text-[17px] leading-[1.76] md:mb-[51px]">
      {contentLeft && (
        <div
          className={`md:max-w-[468px] `}
          dangerouslySetInnerHTML={{ __html: contentLeft }}
        />
      )}
      {contentRight && (
        <div
          className={` md:max-w-[468px]  `}
          dangerouslySetInnerHTML={{ __html: contentRight }}
        />
      )}
    </div>

    {table && <ShareholderTable tables={table} />}
    {bottomText && (
      <div className="max-w-[566px] mt-6 leading-[1.60] font-medium">
        {bottomText}
      </div>
    )}
  </Section>
)

export const layoutQueryFragment = graphql`
  fragment TwoColumnText on WpPage_Page_FlexibleContent {
    ... on WpPage_Page_FlexibleContent_TwoColumnText {
      fieldGroupName
      ...coreCopyStackTwoColumnText
      ...sectionTwoColumnText
      contentLeft
      contentRight
      table {
        tableHeading
        tableRows {
          tableRow
        }
      }
      bottomText
    }
  }
`
