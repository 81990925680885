import { graphql } from "gatsby"
import React from "react"
import { TwoColumnGrid } from "~/components/FlexibleLayouts/elements/Grid"
import { Link } from "~/components/FlexibleLayouts/elements/Link"
import {
  Section,
  SectionProps,
} from "~/components/FlexibleLayouts/elements/Section"
import {
  CoreCopyStack,
  CoreCopyStackProps,
  Heading,
  HeadingSizeOption,
} from "~/components/FlexibleLayouts/elements/Typography"
import { ListItem } from "./DownloadList"

export interface MeetingsProps {
  coreCopyStack: CoreCopyStackProps
  section: SectionProps
  upcomingMeetings: any[]
  meetings: any[]
  ctas: any[]
}

export const Meetings = ({
  coreCopyStack,
  section,
  upcomingMeetings,
  meetings,
  ctas,
}: MeetingsProps) => {
  return (
    <Section {...section}>
      <div className="lg:space-y-36">
        <div className="max-w-[574px] mb-12 md:mb-36">
          <CoreCopyStack
            {...coreCopyStack}
            headingClass="md:!text-[60px] md:!leading-[0.994] font-extrabold "
          />
        </div>

        <TwoColumnGrid>
          <div>
            <div className="max-w-xl">
              <Heading type="h2">{upcomingMeetings.heading}</Heading>
            </div>
          </div>
          <div className="text-21">{upcomingMeetings.content}</div>
        </TwoColumnGrid>

        <div className="mt-12 md:space-y-28 md:mb-0">
          <div>
            <div className="pb-4 border-b border-b-white">
              <Heading type="h6" size={HeadingSizeOption.XSmall}>
                Past meetings{" "}
              </Heading>
            </div>
            <div className="flex flex-col ">
              {meetings.map((meeting, i) => (
                <ListItem
                  layout="simple"
                  download
                  key={i}
                  title={meeting?.link?.title}
                  file={meeting?.file}
                />
              ))}
            </div>
          </div>
          <div className="flex mt-12 lg:space-x-32 md:mt-0">
            {ctas.map((cta, i) => (
              <div className="w-full pl-12 space-y-3 border-l border-white lg:w-1/3">
                <Heading type="h6" size={HeadingSizeOption.XSmall}>
                  {cta.heading}
                </Heading>
                <div>{cta.content}</div>
                <Link {...cta} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </Section>
  )
}

export const layoutQueryFragment = graphql`
  fragment Meetings on WpPage_Page_FlexibleContent {
    ... on WpPage_Page_FlexibleContent_Meetings {
      fieldGroupName
      ...coreCopyStackMeetings
      ...sectionMeetings
      upcomingMeetings {
        heading
        content
      }
      meetings {
        link {
          title
          url
        }
        file {
          localFile {
            publicURL
          }
        }
      }
      ctas {
        behaviour
        content
        videoEmbed
        email
        type
        icon
        heading
        link {
          url
          title
        }
        file {
          localFile {
            publicURL
          }
        }
      }
    }
  }
`
