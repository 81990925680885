import React, { useRef, useState } from "react"
import styled from "styled-components"
import tw from "twin.macro"

import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Navigation, Scrollbar, Pagination } from "swiper"
import "swiper/components/scrollbar/scrollbar.scss"
import "swiper/swiper.scss"

import Image from "../Image"

import TimelineButton from "./buttons/TimelineButton"

const TimelineSliderContainer = styled.div`
  .swiper-button-disabled {
    display: none;
  }

  .swiper-pagination {
    ${tw`flex w-full bg-black bg-opacity-30 md:w-[70%] lg:w-4/5`}
    height: 1px;

    bottom: 0;

    top: unset;
    position: absolute;

    .swiper-pagination-progressbar {
      .swiper-pagination-progressbar-opposite {
        right: 0 !important;
        left: unset;
      }
    }

    .swiper-pagination-progressbar-fill {
      ${tw`bg-black`}
      height: 2px;
    }
  }

  .swiper-container-horizontal > .swiper-pagination-progressbar,
  .swiper-container-vertical
    > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
    right: 0 !important;
    left: unset;
  }
`

function TimelineSlider({ sliders }) {
  SwiperCore.use([Scrollbar, Navigation, Pagination])
  const tlSlider = useRef(null)

  const [activeTab, setActiveTab] = useState(0)

  return (
    <TimelineSliderContainer className="overflow-visible ">
      <Swiper
        onActiveIndexChange={i => setActiveTab(i.activeIndex)}
        // onSwiper={setSwiper}
        className="relative flex flex-col w-full"
        spaceBetween={175}
        slidesPerView={`2.5`}
        ref={tlSlider}
        grabCursor="1"
        pagination={{
          type: "progressbar",
        }}
        breakpoints={{
          320: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          768: {
            slidesPerView: 2.5,
            spaceBetween: 100,
          },
          1024: {
            slidesPerView: 2.5,
            spaceBetween: 175,
          },
        }}
        style={{ overflow: `visible` }}
      >
        {sliders?.map((slider, i) => (
          <SwiperSlide
            key={`tlSlider${i}`}
            className="flex justify-center mb-12 md:mb-28"
            style={{ height: "auto" }}
          >
            <div
              key={`sliderContent${i}`}
              className="max-w-[250px] xl:max-w-[355.5px] text-center md:text-left"
            >
              <div className=" text-h1-b lg:text-[6.5rem] text-brown leading-[1.324] tracking-[-0.03em] font-extrabold  ">
                {slider?.year}
              </div>
              <div
                className={` mt-3`}
                dangerouslySetInnerHTML={{
                  __html: slider?.description,
                }}
              />
              <div className="flex items-center justify-center mt-5 -ml-4 md:justify-start">
                {slider?.logos?.map((logo, i) => (
                  <div key={logo?.id} className="flex items-center pl-4">
                    <Image
                      className="max-w-[80px] max-h-[50px] object-center"
                      image={logo?.logo}
                    />
                  </div>
                ))}
              </div>
            </div>
          </SwiperSlide>
        ))}
        <div className="md:absolute bottom-[-2rem] flex items-center w-max mx-auto mb-5 md:mb-0">
          <TimelineButton
            className="z-50 mr-4 cursor-pointer w-[76px]"
            onClick={() => tlSlider.current.swiper.slidePrev()}
            disabled={activeTab === 0}
          />
          <TimelineButton
            onClick={() => tlSlider.current.swiper.slideNext()}
            className="z-50 rotate-180 cursor-pointer w-[76px]"
            disabled={sliders?.length - 1 === activeTab}
          />
        </div>
      </Swiper>
    </TimelineSliderContainer>
  )
}

export default TimelineSlider
