import React, { Fragment, useEffect } from "react"
import { SecondarySubheading } from "~/components/FlexibleLayouts/elements/Typography"
import { AnimatePresence, motion, useAnimation } from "framer-motion"
import { useInView } from "react-intersection-observer"

export interface NumberedListProps {
  children: React.ReactNode
  disableAnimation?: boolean
}

export const NumberedList = ({ children }: NumberedListProps) => {
  const controls = useAnimation()
  const { ref, inView } = useInView()

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    } else {
      controls.start("hidden")
    }
  }, [controls, inView])

  return (
    <ol ref={ref} className="space-y-2 lg:space-y-[28px]">
      {React.Children.map(children, (child, i) => (
        <motion.li
          className="relative"
          animate={controls}
          initial={"hidden"}
          variants={{
            hidden: { opacity: 0, x: 25 },
            visible: {
              opacity: 1,
              x: 0,
              transition: {
                duration: 0.5,
                delay: i * 0.4,
              },
            },
          }}
          key={`animated-list-${i}`}
        >
          <div className="flex text-[15px] leading-[25px] lg:text-[20px] lg:leading-[27px] ">
            <div className="text-brown font-bold  mr-[10px] sm:w-[35px] lg:w-[90px]">{`${
              i < 9 ? "0" : ""
            }${i + 1}`}</div>
            <div className="flex-1 font-medium">
              <motion.div
                animate={controls}
                initial={"hidden"}
                variants={{
                  hidden: { opacity: 0, x: -40 },
                  visible: {
                    opacity: 1,
                    x: 0,
                    transition: {
                      duration: 0.5,
                      delay: i * 0.4 + 0.2,
                    },
                  },
                }}
                className="max-w-[450px]"
              >
                {child}
              </motion.div>
            </div>
          </div>
        </motion.li>
      ))}
    </ol>
  )
}
