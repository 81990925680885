import { graphql } from "gatsby"
import React from "react"
import {
  Section,
  SectionProps,
} from "~/components/FlexibleLayouts/elements/Section"
import {
  CoreCopyStack,
  CoreCopyStackProps,
} from "~/components/FlexibleLayouts/elements/Typography"

export interface FullWidthCardsProps {
  fullCards: {
    coreCopyStack: CoreCopyStackProps
  }
  section: SectionProps
}

export const FullWidthCards = ({ fullCards, section }: FullWidthCardsProps) => (
  <Section {...section} paddingOverwrite="!py-0 overflow-hidden">
    <div className="flex flex-wrap">
      {fullCards?.map((card, i, arr) => (
        <div key={i} className="w-full md:w-1/2">
          <FullWidthCard {...card} i={i} arr={arr} />
        </div>
      ))}
    </div>
  </Section>
)

export const FullWidthCard = ({ coreCopyStack, i, arr }) => (
  <div className={`w-full h-full relative`}>
    <div
      className={`absolute top-0 bottom-0 right-[-1500px] left-[-1500px] ${
        i === arr?.length - 1
          ? "bg-light-grey md:left-0"
          : "bg-beige md:right-0"
      }`}
    ></div>
    <div
      className={`relative py-16 md:py-[158px] md:max-w-[411px] ${
        i > 0 ? "md:ml-20" : ""
      }`}
    >
      <CoreCopyStack {...coreCopyStack} />
    </div>
  </div>
)

export const layoutQueryFragment = graphql`
  fragment FullWidthCards on WpPage_Page_FlexibleContent {
    ... on WpPage_Page_FlexibleContent_FullWidthCards {
      fieldGroupName
      ...sectionFullWidthCards

      fullCards {
        ...coreCopyStackFullWidthCardsCards
      }
    }
  }
`
