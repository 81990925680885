import { motion } from "framer-motion"
import { graphql, Link } from "gatsby"
import React, { useState } from "react"
import {
  Section,
  SectionProps,
} from "~/components/FlexibleLayouts/elements/Section"
import {
  CoreCopyStack,
  CoreCopyStackProps,
} from "~/components/FlexibleLayouts/elements/Typography"

export interface NewsPostsProps {
  coreCopyStack: CoreCopyStackProps
  section: SectionProps
  posts: any
}

export const NewsPosts = ({
  coreCopyStack,
  section,
  posts,
}: NewsPostsProps) => {
  // If we are using the copy next to this we are showing all posts
  let sliceEnd = -1
  if (coreCopyStack.heading) sliceEnd = 6

  // console.log(posts)

  return (
    <Section {...section}>
      {coreCopyStack.heading && (
        <div className=" max-w-[411px]  lg:mb-[110px] ">
          <CoreCopyStack {...coreCopyStack} />
        </div>
      )}

      <div className="flex flex-wrap lg:flex-nowrap">
        <div className="flex flex-wrap mt-12 -ml-6 md:mt-0">
          {posts?.slice(0, sliceEnd).map((news, i) => (
            <div
              className="pl-6 md:max-w-[384px] mb-[45px] flex-auto md:w-1/2"
              key={news?.id}
            >
              <NewsPost {...news} />
            </div>
          ))}
          <div className="w-1/2 pl-6"></div>
        </div>
      </div>
    </Section>
  )
}

export const NewsPost = ({ date, title, uri }) => {
  const [onHover, setOnHover] = useState(false)

  return (
    <Link
      to={uri}
      onMouseEnter={() => setOnHover(true)}
      onMouseLeave={() => setOnHover(false)}
    >
      <div className="flex flex-col justify-between h-full ">
        <div>
          <div className="flex uppercase text-brown text-[12px] items-center mb-[10px]">
            <div className="font-extrabold mr-[22px]">regulatory news</div>
            <div>{date}</div>
          </div>
          <div className="font-bold text-[20px] lg:text-[24px] leading-[1.30] text-slate">
            {title}
          </div>
        </div>
        <motion.div
          animate={{
            width: onHover ? "80%" : "100%",
            backgroundColor: onHover ? "#A71930" : "#948A85",
          }}
          className="w-full h-[10px] bg-brown mt-[37px]"
        ></motion.div>
      </div>
    </Link>
  )
}

export const layoutQueryFragment = graphql`
  fragment NewsPosts on WpPage_Page_FlexibleContent {
    ... on WpPage_Page_FlexibleContent_NewsPosts {
      fieldGroupName
      ...coreCopyStackNewsPosts
      ...sectionNewsPosts
      posts {
        ... on WpPost {
          categories {
            nodes {
              name
            }
          }
          excerpt
          id
          uri
          title
          featuredImage {
            node {
              ...Image
            }
          }
          date(formatString: "MMMM d YYYY")
        }
      }
    }
  }
`
