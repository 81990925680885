import { graphql } from "gatsby"
import React from "react"
import { Links } from "~/components/FlexibleLayouts/elements/Link"
import {
  Section,
  SectionProps,
} from "~/components/FlexibleLayouts/elements/Section"
import {
  BodyCopy,
  BodyCopySizeOption,
  Heading,
  HeadingSizeOption,
} from "~/components/FlexibleLayouts/elements/Typography"
import useOptions from "~/hooks/useOptions"

export interface TextCtaProps {
  section: SectionProps
  heading: string
  text: string
  links: LinkProps[]
  useGlobalCta: string
}

export const TextCta = (props: TextCtaProps) => {
  const { partnerCta } = useOptions()

  if (props.useGlobalCta === "partner-cta") {
    props = partnerCta
  }

  const { heading, text, links, section } = props

  // console.log(links)
  return (
    <Section {...section}>
      <div className="text-center max-w-[920px] xl:py-[100px] mx-auto">
        <Heading size={HeadingSizeOption.Large}>{heading}</Heading>
        <div className="pt-4 pb-2 md:pt-8 md:pb-4">
          <BodyCopy size={BodyCopySizeOption.Large}>{text}</BodyCopy>
        </div>
        <Links links={links} />
      </div>
    </Section>
  )
}

export const layoutQueryFragment = graphql`
  fragment TextCta on WpPage_Page_FlexibleContent {
    ... on WpPage_Page_FlexibleContent_TextCta {
      fieldGroupName
      useGlobalCta
      heading
      text
      links {
        videoEmbed
        email
        behaviour
        file {
          localFile {
            publicURL
          }
        }
        link {
          title
          url
        }
        type
        icon
      }
      ...sectionTextCta
    }
  }
`
