import React from "react"
import { FlexibleLayout } from "~/components/FlexibleLayouts/layouts"

const prefix = "page_Page_FlexibleContent_"

function removeLayoutNamePrefix(name) {
  return name.replace(prefix, "")
}

export const FlexibleContent = ({ content }) => {
  if (!content) return null

  return content.map(({ fieldGroupName, ...layoutProps }, index) => {
    if (!fieldGroupName) {
      console.log(
        `Flexible layout fieldName not found, please configure graphql for components`
      )
      return null
    }
    const layoutName = removeLayoutNamePrefix(fieldGroupName)
    if (layoutProps.section) {
      // Fill in some useful data for section rendering
      const previousLayout = content[index - 1]
      const nextLayout = content[index + 1]
      layoutProps.section.isFirst = index === 0
      layoutProps.section.isLast = index === content.length - 1
      layoutProps.section.previousSection =
        previousLayout && previousLayout.section ? previousLayout.section : null
      layoutProps.section.nextSection =
        nextLayout && nextLayout.section ? nextLayout.section : null
      layoutProps.section.layoutName = layoutName
    }
    return (
      <FlexibleLayout key={index} layoutName={layoutName} {...layoutProps} />
    )
  })
}

export default FlexibleContent
