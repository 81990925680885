import { graphql, Link } from "gatsby"
import React, { useRef } from "react"
import { useWindowSize } from "react-use"
import styled from "styled-components"
import SwiperCore, { Mousewheel, Scrollbar } from "swiper"
import "swiper/components/scrollbar/scrollbar.scss"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper.scss"
import {
  Section,
  SectionProps,
} from "~/components/FlexibleLayouts/elements/Section"
import useOptions from "~/hooks/useOptions"

const ExploreContainer = styled.div`
  .swiper-wrapper {
    scroll-snap-type: y mandatory;
    .swiper-slide {
      a {
        /* pointer-events: none; */
      }
    }
  }

  .swiper-slide-active {
    a {
      pointer-events: auto !important;
    }
    color: black !important;
    scroll-snap-align: start start;
  }
`

export interface ExploreCta {
  section: SectionProps
}
export const ExploreCta = ({ section }: ExploreCtaProps) => {
  const { exploreCtaLinks } = useOptions()

  SwiperCore.use([Scrollbar, Mousewheel])

  const { width } = useWindowSize()
  const exploreSlider = useRef()

  return (
    <Section {...section} paddingOverwrite="!py-32 md:!pb-[272px]">
      <ExploreContainer className="flex items-center">
        <span className="font-semibold text-[28px] md:text-[40px]  xl:text-[60px] leading-[1.3em] h-[1.3em] flex flex-col justify-center md:flex-row md:justify-start w-full md:w-auto">
          <div className="mx-auto md:mx-0">Explore</div>
          <Swiper
            className="flex flex-col w-full !px-[20px] overflow-visible !py-3 md:!py-0"
            slidesPerView={width < 769 ? 1.5 : "auto"}
            grabCursor={true}
            ref={exploreSlider}
            centeredSlides={true}
            draggable={true}
            mousewheel={{ releaseOnEdges: true }}
            direction={width < 769 ? "horizontal" : "vertical"}
            loopPreventsSlide={true}
            loop={true}
            slideToClickedSlide={true}
            freeMode={true}
            // cssMode={true}
            style={{ overflow: `visible` }}
          >
            {exploreCtaLinks.map((item, i) => (
              <SwiperSlide
                key={`exploreSlider${i}`}
                className=" text-brown"
                style={{ height: "auto" }}
              >
                <div
                  className={`font-bold transition-color flex justify-center md:justify-start `}
                  key={i}
                >
                  <Link to={item.link.url}>{item.link.title}</Link>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="flex items-center justify-center whitespace-nowrap md:justify-start">
            With Future
            <svg
              className="h-[30px] w-[30px] md:h-[50px] md:w-[50px] ml-3 md:ml-5 xl:ml-[35px]"
              width="70"
              height="70"
              viewBox="0 0 70 70"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="35" cy="35" r="35" fill="#231F20" />
              <path
                d="M25.3398 34.1113H42.4218V36.5139H25.3398V34.1113Z"
                fill="white"
              />
              <path
                d="M37.6159 43.3619L36.0723 41.6791L41.914 35.3118L36.0723 28.9445L37.6159 27.2617L44.9991 35.3118L37.6159 43.3619Z"
                fill="white"
              />
            </svg>
          </div>
        </span>
      </ExploreContainer>
    </Section>
  )
}

export const layoutQueryFragment = graphql`
  fragment ExploreCta on WpPage_Page_FlexibleContent {
    ... on WpPage_Page_FlexibleContent_ExploreCta {
      fieldGroupName
      ...sectionExploreCta
    }
  }
`
