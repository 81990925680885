import React, { useState, useRef } from "react"
import { AnimatePresence, motion } from "framer-motion"
import styled from "styled-components"
import cursor from "../assets/images/link-cursor.svg"
import DragCursor from "../assets/images/drag-arrow.svg"
import DragCursorLast from "../assets/images/drag-cursor-last.svg"
import AddCursor from "../assets/images/cursor-add.svg"

const CustomCursorContainer = styled.div`
  cursor: none;
  position: relative;
  z-index: 50;

  .cursor {
    position: absolute;
    top: -20%;
    left: -20px;
    height: 132px;
    width: 132px;

    background-image: url(${cursor});
    background-size: contain;
    transition: all 0.1s ease-in-out;
    transition-property: width, height, border;
    will-change: width, height, transform, border;
    pointer-events: none;
    z-index: 50;

    &.cursor--drag {
      background-image: url(${DragCursor});
    }
    &.cursor--drag-last {
      background-image: url(${DragCursorLast});
    }
    &.cursor--add {
      height: 64px;
      width: 64px;
      background-image: url(${AddCursor});
    }
  }

  .drag-cursor {
    position: absolute;
    top: -20%;
    left: -20px;
    height: 132px;
    width: 132px;

    background-image: url(${DragCursor});
    background-size: contain;
    transition: all 0.1s ease-in-out;
    transition-property: width, height, border;
    will-change: width, height, transform, border;
    pointer-events: none;
    z-index: 50;
  }
`

const CustomCursor = ({
  children,
  className = "",
  dragCursor,
  addCursor,
  last,
}) => {
  const [showCursor, setShowCursor] = useState(false)

  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 })
  const [[rotateX, rotateY, scaleX, scaleY], setMovementAnimation] = useState([
    0, 0, 1, 1,
  ])
  const containerRef = useRef()
  const onMouseMove = e => {
    const { left, top } = containerRef.current.getBoundingClientRect()
    const newX = e.clientX - left
    const newY = e.clientY - top
    const absDeltaX = Math.abs(mousePosition.x - newX)
    const absDeltaY = Math.abs(mousePosition.y - newY)
    setMovementAnimation([
      absDeltaX * 0.5,
      absDeltaY * 0.5,
      1 - absDeltaY * 0.005,
      1 - absDeltaX * 0.005,
    ])
    setMousePosition({ x: newX, y: newY })
  }

  return (
    <CustomCursorContainer
      className={`z-30 ${className}`}
      ref={containerRef}
      onMouseMove={onMouseMove}
      onMouseEnter={() => setShowCursor(true)}
      onMouseLeave={() => setShowCursor(false)}
    >
      <AnimatePresence>
        {showCursor && (
          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            className={`cursor ${
              dragCursor && !last
                ? "cursor--drag"
                : dragCursor && last
                ? "cursor--drag-last"
                : addCursor
                ? "cursor--add"
                : "cursor"
            } `}
            style={{
              left: mousePosition.x,
              top: mousePosition.y,
            }}
            animate={{
              translateX: -66,
              translateY: -66,
              opacity: 1,
              scale: 1,
            }}
            exit={{ opacity: 0, scale: 0 }}
            transition={{ opacity: { duration: 0.2, ease: "easeInOut" } }}
          />
        )}
      </AnimatePresence>

      {children}
    </CustomCursorContainer>
  )
}

export default CustomCursor
