import { graphql } from "gatsby"
import React from "react"
import {
  Section,
  SectionProps,
} from "~/components/FlexibleLayouts/elements/Section"
import {
  CoreCopyStack,
  CoreCopyStackProps,
} from "~/components/FlexibleLayouts/elements/Typography"
import EventsSlider from "~/components/sliders/EventsSlider"

export interface ExperiencesSlider {
  coreCopyStack: CoreCopyStackProps
  section: SectionProps
  slider: any
}

export const ExperiencesSlider = ({
  coreCopyStack,
  section,
  slider,
}: ExperiencesSliderProps) => (
  <Section {...section} paddingOverwrite="overflow-hidden">
    <div className="max-w-[560px] mb-6 md:mb-20">
      <CoreCopyStack {...coreCopyStack} />
    </div>
    <EventsSlider sliders={slider} />
  </Section>
)

export const layoutQueryFragment = graphql`
  fragment ExperiencesSlider on WpPage_Page_FlexibleContent {
    ... on WpPage_Page_FlexibleContent_ExperiencesSlider {
      fieldGroupName
      ...sectionExperiencesSlider
      ...coreCopyStackExperiencesSlider
      slider {
        heading
        content
        image {
          ...Image
        }
        stats {
          stat
          description
        }
      }
    }
  }
`
