import React, { Fragment, useState } from "react"
import { graphql } from "gatsby"
import {
  Section,
  SectionProps,
} from "~/components/FlexibleLayouts/elements/Section"
import {
  CoreCopyStack,
  CoreCopyStackProps,
  Heading,
  Subheading,
} from "~/components/FlexibleLayouts/elements/Typography"
import { AnimatePresence, motion } from "framer-motion"
import Image from "~/components/Image"

export interface TrackRecordProps {
  coreCopyStack: CoreCopyStackProps
  section: SectionProps
  records: any[]
}

export const TrackRecord = ({
  coreCopyStack,
  section,
  records,
}: TrackRecordProps) => {
  const [checked, setChecked] = useState(0)

  return (
    <Section {...section}>
      <div className="relative ">
        <div className="flex flex-wrap lg:flex-nowrap">
          <div className="lg:w-[40%]">
            <div className="lg:max-w-[479px] sticky top-[7.5rem]">
              <CoreCopyStack {...coreCopyStack} />
            </div>
          </div>
          <div className="mt-12 lg:w-[60%] lg:ml-12 lg:mt-0">
            <div className="flex ml-[-21px] mb-[33px] font-extrabold uppercase text-[16px] leading-[1.569]">
              <div className="pl-[21px]">
                <div
                  className={` rounded-[7px] border border-beige cursor-pointer py-[10px] px-[25px] transition-colors duration-200 ease-in-out  ${
                    checked === 0
                      ? "bg-beige !text-slate"
                      : "bg-transparent !text-brown hover:bg-beige hover:!text-slate"
                  } `}
                  onClick={() => setChecked(0)}
                >
                  {records[0]?.label}
                </div>
              </div>
              <div className="pl-[21px]">
                <div
                  className={` rounded-[7px] border border-beige cursor-pointer py-[10px] px-[25px] transition-colors duration-200 ease-in-out   ${
                    checked === 1
                      ? "bg-beige !text-slate"
                      : "bg-transparent !text-brown hover:bg-beige hover:!text-slate"
                  } `}
                  onClick={() => setChecked(1)}
                >
                  {records[1]?.label}
                </div>
              </div>
            </div>
            <div className="flex flex-wrap space-y-6 ">
              {records?.map((recordGroup, i) => (
                <React.Fragment key={`recordGroup${i}`}>
                  <AnimatePresence>
                    {checked === i &&
                      recordGroup?.records?.map((record, i) => (
                        <Record image={record?.image} i={i} />
                      ))}
                  </AnimatePresence>
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export const Record = ({ image, i }) => (
  <motion.div
    key={`record${i}`}
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 0.7, delay: (i + 1) / 12 }}
    className="w-full"
  >
    <div className="bg-black  rounded-lg lg:max-w-[612px] py-[63px] px-5">
      <Image image={image} className="max-w-[447px] mx-auto" />
    </div>
  </motion.div>
)

export const layoutQueryFragment = graphql`
  fragment TrackRecord on WpPage_Page_FlexibleContent {
    ... on WpPage_Page_FlexibleContent_TrackRecord {
      fieldGroupName
      ...coreCopyStackTrackRecord
      ...sectionTrackRecord
      records {
        label
        records {
          image {
            ...Image
          }
        }
      }
    }
  }
`
