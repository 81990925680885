import { graphql } from "gatsby"
import React from "react"
import {
  Section,
  SectionProps,
} from "~/components/FlexibleLayouts/elements/Section"
import {
  CoreCopyStack,
  CoreCopyStackProps,
} from "~/components/FlexibleLayouts/elements/Typography"

export interface TextCardSectionProps {
  section: SectionProps
  coreCopyStack: CoreCopyStackProps
}

export const TextCardSection = ({
  coreCopyStack,
  section,
}: TextCardSectionProps) => (
  <Section {...section} paddingOverwrite="!pb-0">
    <CoreCopyStack {...coreCopyStack} />
  </Section>
)

export const layoutQueryFragment = graphql`
  fragment TextCardSection on WpPage_Page_FlexibleContent {
    ... on WpPage_Page_FlexibleContent_TextCardSection {
      fieldGroupName
      ...sectionTextCardSection
      ...coreCopyStackTextCardSection
    }
  }
`
