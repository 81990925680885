import { graphql } from "gatsby"
import React from "react"
import {
  Section,
  SectionProps,
} from "~/components/FlexibleLayouts/elements/Section"
import {
  CoreCopyStack,
  CoreCopyStackProps,
} from "~/components/FlexibleLayouts/elements/Typography"

export interface HubCtaProps {
  coreCopyStack: CoreCopyStackProps
  section: SectionProps
}

export const HubCta = ({ coreCopyStack, section }: HubCtaProps) => (
  <Section {...section}>
    <div className="text-center max-w-[620px] md:py-[40px] mx-auto">
      <CoreCopyStack {...coreCopyStack} />
    </div>
  </Section>
)

export const layoutQueryFragment = graphql`
  fragment HubCta on WpPage_Page_FlexibleContent {
    ... on WpPage_Page_FlexibleContent_HubCta {
      fieldGroupName
      ...coreCopyStackHubCta
      ...sectionHubCta
    }
  }
`
