import { AnimatePresence } from "framer-motion"
import React, { useState } from "react"

import FinancialHighlight from "~/components/FinancialHighlight"
import { Heading, Subheading } from "./FlexibleLayouts/elements/Typography"

function FinancialHighlights({ text, highlights }) {
  const [checked, setChecked] = useState(0)

  return (
    <div className="relative ">
      <div className="flex flex-wrap">
        <div className="lg:w-[40%] relative">
          <div className="md:max-w-[25.75rem] sticky top-[7.5rem]">
            <Subheading className="mb-6"> {text?.subheading} </Subheading>

            <div className="mt-5">
              <Heading> {text?.heading}</Heading>
            </div>
            <div className="flex ml-[-21px] mt-[46px] font-extrabold text-[16px] leading-[1.569]">
              <div className="pl-[21px]">
                <div
                  className={` rounded-[7px] border border-brown cursor-pointer py-[10px] px-[25px] transition-colors duration-200 ease-in-out ${
                    checked === 0
                      ? "bg-black text-white"
                      : "bg-white text-brown hover:bg-black hover:text-white"
                  } `}
                  onClick={() => setChecked(0)}
                >
                  {highlights[0]?.label}
                </div>
              </div>
              <div className="pl-[21px]">
                <div
                  className={` rounded-[7px] border border-brown cursor-pointer py-[10px] px-[25px] transition-colors duration-200 ease-in-out  ${
                    checked === 1
                      ? "bg-black text-white "
                      : "bg-white text-brown hover:bg-black hover:text-white"
                  } `}
                  onClick={() => setChecked(1)}
                >
                  {highlights[1]?.label}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-12 lg:w-[60%] lg:mt-0">
          <div className="mb-12">
            <Heading> {highlights[checked]?.heading}</Heading>
          </div>
          <div className="flex flex-wrap -ml-6 md:-ml-12 xl:ml-[-7.75rem]">
            {highlights?.map((hightlightGroup, i) => (
              <React.Fragment key={`highlightGroup${i}`}>
                <AnimatePresence>
                  {checked === i &&
                    hightlightGroup?.highlights?.map((highlight, i, arr) => (
                      <FinancialHighlight
                        i={i}
                        key={`relationsHighlights${i}`}
                        {...highlight}
                        className={`pl-6 md:pl-12 xl:pl-[7.75rem] w-1/2 ${
                          i >= arr.length - 2 ? "mb-0" : "mb-12 md:mb-32"
                        } `}
                      />
                    ))}
                </AnimatePresence>
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default FinancialHighlights
