import React, { useState, useEffect } from "react"
import styled from "styled-components"
import tw from "twin.macro"
import VennSingleImage from "~/components/animations/VennSingleImage"
import { motion, AnimateSharedLayout, AnimatePresence } from "framer-motion"

import Image from "~/components/Image"

const SectionStyled = styled.section`
  .circle-container {
    ${tw`relative flex w-full mx-auto`}
    max-width: 1196px;
  }

  .intersection {
    ${tw`absolute bottom-0 z-10 pointer-events-none`}
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: calc(100% - 20%);
  }

  .circle {
    ${tw`relative w-1/2 overflow-hidden rounded-full`}
    background: #eee;
    &:before {
      content: "";
      ${tw`block w-full`}
      padding-bottom: 100%;
    }

    > * {
      ${tw`absolute inset-0 w-full h-full`}
    }

    &.circle-left {
      transform: translateX(20%);
    }

    &.circle-right {
      transform: translateX(-20%);
    }
  }

  .logo {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10%;
    height: 5em;
    z-index: 11;
    margin-left: -4.75%;
    margin-top: -2.5em;
  }
`

export default function VennImages({ diagrams }) {
  const data = diagrams?.map(diagram => ({
    logo: diagram.logo,
    imgLeft: diagram.image1,
    imgRight: diagram.image2,
  }))

  const [active, setActive] = useState(0)
  const textures = ["firstTexture", "secondTexture", "thirdTexture"]

  useEffect(() => {
    let timeout
    const loop = () => {
      timeout = setTimeout(() => {
        setActive(active => (active === data?.length - 1 ? 0 : active + 1))
        loop()
      }, 5000)
    }
    loop()
    return () => {
      if (timeout) clearTimeout(timeout)
    }
  }, [])

  return (
    <SectionStyled>
      <div className="circle-container">
        <div className="circle circle-left">
          <div className="circle-image">
            <AnimatePresence>
              {data?.map(
                (item, i) =>
                  active === i && (
                    <motion.div
                      key={i}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      style={{
                        objectFit: "contain",
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <Image
                        image={item.imgLeft}
                        objectFit="contain"
                        className="absolute w-full h-full image-absolute"
                      />
                    </motion.div>
                  )
              )}
            </AnimatePresence>
          </div>
        </div>

        <svg
          className="intersection"
          viewBox="0 0 246 487"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M122.999.514C48.409 55.5.021 143.975.021 243.75c0 99.776 48.389 188.25 122.978 243.236 74.59-54.986 122.978-143.46 122.978-243.236 0-99.776-48.388-188.25-122.978-243.237z"
            fill="#EBEAE8"
          />
        </svg>

        <div className="circle circle-right">
          <div className="circle-image">
            <AnimatePresence>
              {data?.map(
                (item, i) =>
                  active === i && (
                    <motion.div
                      key={i}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      style={{
                        objectFit: "contain",
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <Image
                        image={item.imgRight}
                        objectFit="contain"
                        className="absolute w-full h-full image-absolute"
                      />
                    </motion.div>
                  )
              )}
            </AnimatePresence>
          </div>
        </div>

        <div className="logo">
          <AnimatePresence>
            {data?.map(
              (item, i) =>
                active === i && (
                  <motion.div
                    key={i}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    style={{
                      objectFit: "contain",
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <Image
                      image={item.logo}
                      objectFit="contain"
                      className="absolute w-full h-full image-absolute"
                    />
                  </motion.div>
                )
            )}
          </AnimatePresence>
        </div>
      </div>
    </SectionStyled>
  )
}
