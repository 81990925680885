import React, { Fragment } from "react"
import { graphql } from "gatsby"
import Image from "~/components/Image"

export enum MediaType {
  Image = "Image",
  Video = "Video",
}

export interface MediaProps {
  type: MediaType
  image: any
}

export const Media = ({ type, image }: MediaProps) => {
  // @todo implement video if required
  if (!image) return null
  return <Image objectFit="cover" image={image} className="w-full h-full" />
}

export const MediaFragment = graphql`
  fragment mediaTextAndMediaColumns on WpPage_Page_FlexibleContent_TextAndMediaColumns {
    media {
      type
      image {
        ...Image
      }
    }
  }
  fragment mediaTextAndMediaColumnsContained on WpPage_Page_FlexibleContent_TextAndMediaColumnsContained {
    media {
      type
      image {
        ...Image
      }
    }
  }
`
