import { graphql } from "gatsby"
import React from "react"
import { useWindowSize } from "react-use"
import "swiper/components/scrollbar/scrollbar.scss"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper.scss"
import {
  Section,
  SectionProps,
} from "~/components/FlexibleLayouts/elements/Section"
import {
  BodyCopySizeOption,
  CoreCopyStack,
  CoreCopyStackProps,
} from "~/components/FlexibleLayouts/elements/Typography"
import { Card } from "../elements/Card"

export interface CardSliderProps {
  coreCopyStack: CoreCopyStackProps
  section: SectionProps
  sliders: any[]
}

export const CardSlider = ({
  coreCopyStack,
  section,
  sliders,
}: CardSliderProps) => {
  const { width } = useWindowSize()

  return (
    <Section {...section} paddingOverwrite="overflow-hidden">
      <div className="mb-[73px] ">
        <CoreCopyStack {...coreCopyStack} />
      </div>
      <Swiper
        className="flex flex-col w-full"
        slidesPerView={width < 476 ? 1.1 : `auto`}
        grabCursor="1"
        style={{ overflow: `visible` }}
      >
        {sliders?.map((slider, i) => (
          <SwiperSlide
            key={`cardSlider${i}`}
            className=" bg-slate-invert-on-slate last:mr-0  max-w-[384px] !h-auto mr-7  "
          >
            <Card
              card={{
                coreCopyStack: {
                  heading: slider.heading,
                  text: slider.text,
                  links: [{ link: { ...slider.link }, icon: "arrow-right" }],
                },
              }}
              scrollText={slider?.scrolltext}
              scrollClass="xl:text-[204px] !leading-[1.2] pt-8"
              headingClass="md:!text-[24px]"
              bodySize={BodyCopySizeOption.Small}
              textContainerClass="md:px-[48px] md:py-[51px]"
              linkClass=""
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </Section>
  )
}

export const layoutQueryFragment = graphql`
  fragment CardSlider on WpPage_Page_FlexibleContent {
    ... on WpPage_Page_FlexibleContent_CardSlider {
      fieldGroupName
      ...coreCopyStackCardSlider
      ...sectionCardSlider
      sliders {
        scrolltext
        heading
        text
        link {
          title
          url
        }
      }
    }
  }
`
