import { graphql, useStaticQuery } from "gatsby"

const useBrandTaxonomies = () => {
  const data = useStaticQuery(graphql`
    query {
      allWpBrandTaxonomy(sort: { fields: name, order: ASC }) {
        nodes {
          name
          id
          brandTax {
            brand {
              heading
              content
              stats {
                stat
                description
              }
              image {
                ...Image
              }
            }
          }
        }
      }
    }
  `)

  return data
}

export default useBrandTaxonomies
