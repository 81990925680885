import { graphql, useStaticQuery } from "gatsby"

const useBrands = () => {
  const data = useStaticQuery(graphql`
    query {
      allWpBrand(
        sort: { fields: brandPost___modal___companyName, order: ASC }
      ) {
        nodes {
          id
          brandPost {
            modal {
              companyName
              content
              heading
              images {
                image {
                  ...Image
                }
              }
              statsToShow
              brandStats {
                users
                usUsers
                usSessions
                usPageviews
                ukUsers
                ukSessions
                ukPageviews
                sessions
                seoSessions
                search
                pageviews
                male
                fieldGroupName
                female
                caUsers
                caSessions
                caSessionsLabel
                caUsersLabel
                femaleLabel
                maleLabel
                pageviewsLabel
                searchLabel
                seoSessionsLabel
                sessionsLabel
                ukPageviewsLabel
                ukSessionsLabel
                ukUsersLabel
                usPageviewsLabel
                usSessionsLabel
                usUsersLabel
                usersLabel
              }
              stats {
                stat
                description
              }
              enquiries {
                url
                title
              }
              companyWebsite {
                url
                title
              }
            }
            brandPostPreview {
              logo {
                ...Image
              }
            }
          }
          brandTaxonomies {
            nodes {
              name
            }
          }
          importedBrandLogos {
            importedBrandLogos {
              ...Image
            }
          }
        }
      }
    }
  `)

  return data
}

export default useBrands
