import { graphql } from "gatsby"
import React from "react"
import { Card } from "~/components/FlexibleLayouts/elements/Card"
import {
  Section,
  SectionProps,
} from "~/components/FlexibleLayouts/elements/Section"
import {
  BodyCopySizeOption,
  SecondaryCopyStackProps,
} from "~/components/FlexibleLayouts/elements/Typography"

export interface StaggeredCardsProps {
  section: SectionProps
  staggeredCards: {
    secondaryCopyStack: SecondaryCopyStackProps[]
  }
}

export const StaggeredCards = ({
  section,
  staggeredCards,
}: StaggeredCardsProps) => (
  <Section {...section}>
    <div className="">
      <div className="flex flex-wrap -ml-6">
        {staggeredCards?.map((card, i) => (
          <div
            key={i}
            className={`w-full lg:w-1/2 pl-6 ${
              i === staggeredCards.length - 1 && " mt-6 lg:mt-[169px]"
            }`}
          >
            <Card
              card={card}
              bodySize={BodyCopySizeOption.Small}
              maxWidth="max-w-[473px]"
              linkClass="text-[15px] mt-[-3px]"
            />
          </div>
        ))}
      </div>
    </div>
  </Section>
)

export const layoutQueryFragment = graphql`
  fragment StaggeredCards on WpPage_Page_FlexibleContent {
    ... on WpPage_Page_FlexibleContent_StaggeredCards {
      fieldGroupName
      ...sectionStaggeredCards
      staggeredCards {
        ...copyStackStaggeredCardsCards
        image {
          ...Image
        }
        icon {
          ...Image
        }
      }
    }
  }
`
