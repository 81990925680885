import { motion } from "framer-motion"
import React, { useState } from "react"
import Image from "./Image"

function BrandPreview({
  logo,
  logoFallback,
  taxonomies,
  description,
  i,
  ...other
}) {
  const [onHover, setOnHover] = useState(false)

  return (
    <div
      className={`flex flex-col justify-between h-full transition-opacity duration-200 cursor-pointer lg:pr-6 lg:pb-6 md:opacity-100`}
      {...other}
      onMouseEnter={() => setOnHover(true)}
      onMouseLeave={() => setOnHover(false)}
    >
      <div>
        <div className="lg:min-h-[140px] flex flex-col">
          <div className="flex flex-wrap self-start">
            {taxonomies?.nodes?.map((tax, i) => (
              <div
                key={`tax${i}`}
                className="text-[12px] leading-[1.2] text-brown font-bold "
              >
                {tax?.name}
                {i !== taxonomies?.nodes?.length - 1 && <>,&nbsp;</>}
              </div>
            ))}
          </div>
          <div className="pointer-events-none h-[75px] gatsby-image-auto flex flex-col justify-center mt-6 lg:mt-auto ">
            <Image
              image={logo || (logoFallback && logoFallback[0])}
              className="max-h-[75px] w-auto gatsby-image-auto actually-object-contain object-contain object-left "
              objectFit="contain"
              imgClassName="h-auto"
            />
          </div>
        </div>

        <div className="mt-[30px] font-medium leading-[1.604]">
          {description}
        </div>
      </div>
      <motion.div
        animate={{
          width: onHover ? "80%" : "100%",
          backgroundColor: onHover ? "#000000" : "#D5D2CA",
        }}
        transition={{ duration: 0.3, ease: "easeInOut" }}
        className="h-px bg-beige mt-[38px]"
      ></motion.div>
    </div>
  )
}

export default BrandPreview
