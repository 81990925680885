import React from "react"
import styled from "styled-components"
import tw from "twin.macro"

const Table = styled.table`
  ${tw`w-full`}

  th {
    ${tw`w-1/5 py-6 font-extrabold uppercase border border-collapse border-dashed border-brown border-opacity-20 `};
    background-color: #edecea;
  }
  td {
    ${tw`text-center bg-transparent border-collapse border-dashed md:border lg:py-8 border-brown border-opacity-20 md:bg-white `};
  }

  tr {
    ${tw`border border-collapse border-dashed border-brown border-opacity-20`};
  }
`

function ShareholderTable({ tables }) {
  const firstRow = tables ? tables[0].tableRows : []
  return (
    <div className="flex w-full mt-12 md:mt-[100px]">
      <Table className="collapse-table">
        <thead className="hidden lg:table-header-group">
          {tables?.map((col, i) => (
            <th key={i}>{col?.tableHeading}</th>
          ))}
        </thead>
        <tbody>
          {firstRow &&
            firstRow.map((row, i) => (
              <tr
                className="block py-6 space-y-4 lg:py-0 lg:space-y-0 lg:table-row"
                key={i}
              >
                {tables?.map((col, r) => {
                  const cell = col.tableRows[i]
                  return (
                    <td className="block lg:table-cell" key={r}>
                      <span className="block text-xs font-bold lg:hidden">
                        {col?.tableHeading}
                      </span>
                      <span>{cell ? cell?.tableRow : null}</span>
                    </td>
                  )
                })}
              </tr>
            ))}
        </tbody>
      </Table>
    </div>
  )
}

export default ShareholderTable
