import { graphql } from "gatsby"
import React from "react"
import VennImages from "~/components/animations/VennImages"
import {
  Section,
  SectionProps,
} from "~/components/FlexibleLayouts/elements/Section"
import {
  BodyCopy,
  BodyCopySizeOption,
  Heading,
  XLSubheading,
} from "~/components/FlexibleLayouts/elements/Typography"
import { Links } from "../elements/Link"

export interface VennDiagramProps {
  coreCopyStack: CoreCopyStackProps
  media: MediaProps
  section: SectionProps
}

export const VennDiagram = ({
  coreCopyStack,
  media,
  section,
  diagrams,
}: VennDiagramProps) => {
  // console.log(section)
  return (
    <Section {...section}>
      <div className="max-w-3xl mx-auto text-center">
        <div className="mb-[21px] text-[30px]">
          {/* <SecondarySubheading>{coreCopyStack?.subheading}</SecondarySubheading> */}
          <XLSubheading>{coreCopyStack?.subheading}</XLSubheading>
        </div>

        <Heading>{coreCopyStack?.heading}</Heading>
        {coreCopyStack?.text && (
          <BodyCopy size={BodyCopySizeOption.Small}>
            {coreCopyStack?.text}
          </BodyCopy>
        )}
        <div className="mt-[39px]">
          <Links links={coreCopyStack?.links} />
        </div>
      </div>
      {diagrams?.length > 0 && (
        <div className="flex-auto hidden w-full mt-12 md:mt-[83px] md:block">
          <VennImages diagrams={diagrams} />
        </div>
      )}
    </Section>
  )
}

export const layoutQueryFragment = graphql`
  fragment VennDiagram on WpPage_Page_FlexibleContent {
    ... on WpPage_Page_FlexibleContent_VennDiagram {
      fieldGroupName
      ...coreCopyStackVennDiagram
      ...sectionVennDiagram
      diagrams {
        logo {
          ...Image
        }
        image1 {
          ...Image
        }
        image2 {
          ...Image
        }
      }
    }
  }
`
