import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import {
  Section,
  SectionProps,
} from "~/components/FlexibleLayouts/elements/Section"
import { Heading } from "~/components/FlexibleLayouts/elements/Typography"
import useBrands from "~/hooks/useBrands"
import useBrandTaxonomies from "~/hooks/useBrandTaxonomies"
import { AnimatePresence, motion } from "framer-motion"
import BrandPreview from "~/components/BrandPreview"
import BrandsModal from "~/components/BrandsModal"
import LoadMoreButton from "~/components/buttons/LoadMoreButton"
import Image from "~/components/Image"
import DownArrow from "~/images/down-arrow.svg"

export interface BrandsProps {
  coreCopyStack: CoreCopyStackProps
  section: SectionProps
  defaultContent: any
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const Brands = ({
  section,
  coreCopyStack,
  defaultContent,
}: BrandsProps) => {
  const brands = useBrands()
  const taxonomies = useBrandTaxonomies()
  const allWpBrand = brands.allWpBrand
  const allWpBrandTaxonomy = taxonomies.allWpBrandTaxonomy

  const [showModal, setShowModal] = useState(false)
  const [activeIndex, setActiveIndex] = useState(0)
  const [onHover, setOnHover] = useState(false)
  const [selected, setSelected] = useState(false)
  const [isAll, setIsAll] = useState(false)
  const [hoverIndex, setHoverIndex] = useState(null)

  const [activeTax, setActiveTax] = useState(null)
  const [filter, setFilter] = useState(null)
  const [filteredBrands, setFilteredBrands] = useState(allWpBrand?.nodes)
  const [showMore, setShowMore] = useState(15)

  const enterHandler = i => {
    setSelected(i)
    setOnHover(true)
  }

  const modalHandler = i => {
    setShowModal(true)
    setActiveIndex(i)
  }

  const navHandler = (i, tax) => {
    setActiveTax(i)
    setFilter(tax)
    setShowMore(15)
  }

  const reset = () => {
    setFilter(null)
    setShowMore(15)
    setActiveTax(null)
    setIsAll(false)
    setFilteredBrands(defaultContent?.allBrands)
  }
  const viewAll = () => {
    setShowMore(15)
    setFilteredBrands(
      allWpBrand?.nodes.sort((a, b) =>
        a.brandPost.modal.companyName.localeCompare(
          b.brandPost.modal.companyName
        )
      )
    )
    setActiveTax(null)
    setIsAll(true)
  }

  const handleChange = e => {
    if (e !== null) {
      let obj = JSON.parse(e.target.value)

      navHandler(obj?.i, obj?.tax)
    } else reset()
  }
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    if (!loaded) {
      setLoaded(true)
      const anchor = window.top.location.hash.substr(1)
      if (anchor) {
        // This should be changed to not set two separate values

        const capAnchor = capitalizeFirstLetter(anchor)

        const search = obj => obj.name === capAnchor
        const matchingHash = allWpBrandTaxonomy?.nodes.findIndex(search)

        setActiveTax(matchingHash)
        setFilter(capAnchor)
        setShowMore(15)
      }
    } else {
      setFilteredBrands(
        filter !== null
          ? allWpBrand?.nodes.filter(brand =>
              brand?.brandTaxonomies?.nodes?.some(tax => tax?.name === filter)
            )
          : defaultContent?.allBrands
      )
    }
  }, [loaded, filter, allWpBrand])

  return (
    <Section {...section}>
      <div className="relative flex flex-wrap ">
        <aside className="w-full lg:w-[20%] ">
          <div className="sticky hidden mb-6 md:mb-0 lg:block top-[9.5rem]">
            <motion.div
              className="flex items-center pb-[8px] "
              onMouseEnter={() => enterHandler(null)}
              onMouseLeave={() => setOnHover(false)}
            >
              <motion.div
                onClick={reset}
                className={`text-16 relative cursor-pointer leading-[1.269] hover:text-red  font-bold  mr-3 md:mr-0  transition-colors duration-200 ease-in-out ${
                  activeTax === null && !isAll ? "text-black" : "text-brown"
                }  `}
              >
                Brands Home
              </motion.div>
            </motion.div>
            <motion.div
              className="flex items-center py-[8px] "
              onMouseEnter={() => enterHandler(-1)}
              onMouseLeave={() => setOnHover(false)}
            >
              <motion.div
                onClick={viewAll}
                className={`text-16 relative cursor-pointer hover:text-red  leading-[1.269]  font-bold  mr-3 md:mr-0  transition-colors duration-200 ease-in-out ${
                  activeTax === null && isAll ? "text-black" : "text-brown"
                }  `}
              >
                A-Z
              </motion.div>
            </motion.div>
            {allWpBrandTaxonomy?.nodes?.map((cat, i) => (
              <motion.div
                key={`catNav${i}`}
                layout
                onClick={() => navHandler(i, cat?.name)}
                onMouseEnter={() => enterHandler(i)}
                onMouseLeave={() => setOnHover(false)}
                className="relative flex items-center py-[8px] cursor-pointer "
              >
                <motion.div
                  initial={{ x: 0 }}
                  animate={{ x: onHover && selected === i ? 10 : 0 }}
                  // exit={{ x: 0 }}

                  key={`nav${i}`}
                  layout
                  // transition={{ duration: 0.3 }}
                  className={`text-16 cursor-pointer leading-[1.269] w-full  font-bold  mr-3 md:mr-0  transition-colors duration-200 ease-in-out ${
                    selected === i && onHover ? "text-red" : "text-brown"
                  } ${activeTax === i ? "text-black" : "text-brown"} `}
                >
                  {cat?.name}
                </motion.div>
              </motion.div>
            ))}
          </div>
        </aside>
        <div className="w-full lg:w-[73%] lg:ml-auto">
          <div className="flex flex-wrap lg:flex-nowrap bg-[#222D33] lg:min-h-[480px] lg:max-h-[520px] lg:h-full ">
            <div className="flex flex-col px-6 pt-12 xl:px-[71px] lg:py-[86px] text-white justify-between w-full lg:w-1/2 ">
              <div>
                <Heading className="lg:text-[35px] normal-case leading-[1.269] font-bold">
                  {activeTax === null
                    ? defaultContent?.heading
                    : allWpBrandTaxonomy?.nodes[activeTax]?.brandTax?.brand
                        ?.heading}
                </Heading>

                <div
                  className="mt-[11px] text-[15px] leading-[25px] md:text-[16px] md:leading-[26px] "
                  dangerouslySetInnerHTML={{
                    __html:
                      activeTax === null
                        ? defaultContent?.text
                        : allWpBrandTaxonomy?.nodes[activeTax]?.brandTax?.brand
                            ?.content,
                  }}
                ></div>
              </div>
              <div>
                {/* <div className="h-[4px] bg-white bg-opacity-10 w-full mt-10 lg:mt-20"></div>

                <div className="flex mt-8">
                  {activeTax === null
                    ? defaultContent.stats?.map((stat, i, arr) => (
                        <Stats {...stat} i={i} key={i} arr={arr} />
                      ))
                    : allWpBrandTaxonomy?.nodes[
                        activeTax
                      ]?.brandTax?.brand?.stats?.map((stat, i, arr) => (
                        <Stats {...stat} i={i} key={i} arr={arr} />
                      ))}
                </div> */}
              </div>
            </div>
            <div className="self-end w-full lg:w-1/2 mt-12 lg:mt-0 bg-[#141b1f] lg:h-full">
              <Image
                className="image-absolute max-w-[600px] img-fade-left h-full"
                objectFit="cover"
                image={
                  activeTax === null
                    ? defaultContent?.image
                    : allWpBrandTaxonomy?.nodes[activeTax]?.brandTax?.brand
                        ?.image
                }
              />
            </div>
          </div>

          <form action="" className="lg:hidden mt-[70px] relative">
            <div className="relative inset-0">
              <img
                src={DownArrow}
                alt="down arrow"
                className="absolute top-[35%] right-[1.5rem] h-[15px]"
              />
              <select
                className="text-[16px] bg-white uppercase leading-[1.439] focus:outline-none font-bold border w-full md:w-auto rounded-md border-black py-3 px-5 appearance-none  "
                onChange={handleChange}
              >
                <option value={JSON.stringify({ i: null, tax: null })}>
                  Brands Home
                </option>
                {allWpBrandTaxonomy?.nodes?.map((cat, i) => (
                  <option
                    key={i}
                    // onClick={() => setFilter(cat?.name)}
                    value={JSON.stringify({ i: i, tax: cat?.name })}
                  >
                    {cat?.name}
                  </option>
                ))}
              </select>
            </div>
          </form>
          <div className="flex flex-wrap -ml-8 mt-12 md:mt-[55px] ">
            {filteredBrands?.slice(0, showMore).map((brand, i) => (
              <motion.div
                layoutId={`modal${i}`}
                key={`${brand?.id}${i}`}
                onClick={() => modalHandler(i)}
                onMouseEnter={() => setHoverIndex(i)}
                onMouseLeave={() => setHoverIndex(null)}
                className={`w-full pl-8 mb-8 sm:w-1/2 lg:w-1/3 transition-opacity duration-200 ease-in-out ${
                  hoverIndex !== null && i !== hoverIndex
                    ? "opacity-40"
                    : "opacity-100"
                }`}
              >
                <BrandPreview
                  {...brand?.brandPost?.brandPostPreview}
                  i={i}
                  logoFallback={brand?.importedBrandLogos?.importedBrandLogos}
                  taxonomies={brand?.brandTaxonomies}
                  description={brand?.brandPost?.modal?.heading}
                />
              </motion.div>
            ))}
          </div>
          <div className="flex justify-center mt-12 md:mt-20 ">
            {filteredBrands?.length > showMore && (
              <LoadMoreButton onClick={() => setShowMore(showMore + 6)} />
            )}
          </div>
        </div>

        <AnimatePresence>
          {showModal && (
            <motion.div
              onClick={() => setShowModal(false)}
              key={`modal`}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
              className="fixed top-0 bottom-0 left-0 right-0 z-50 bg-black bg-opacity-40"
            ></motion.div>
          )}
        </AnimatePresence>

        <AnimatePresence>
          {showModal && (
            <BrandsModal
              showModal={showModal}
              setShowModal={setShowModal}
              activeIndex={activeIndex}
              category={filteredBrands[activeIndex]?.brandTaxonomies?.nodes}
              logo={
                filteredBrands[activeIndex]?.brandPost?.brandPostPreview
                  ?.logo ||
                (filteredBrands[activeIndex]?.importedBrandLogos
                  ?.importedBrandLogos &&
                  filteredBrands[activeIndex]?.importedBrandLogos
                    ?.importedBrandLogos[0])
              }
              {...filteredBrands[activeIndex]?.brandPost?.modal}
            />
          )}
        </AnimatePresence>
      </div>
    </Section>
  )
}

export const Stats = ({ stat, description, arr, i }) => (
  <div
    key={`leadingBrandsStat${i}`}
    className={`flex flex-col ${i !== arr.length - 1 && " mr-12 md:mr-28"}`}
  >
    <div className="flex text-white text-[34px] leading-[0.98] tracking-[-0.015em] font-semibold mb-2 ">
      {stat}
    </div>
    <p className="font-medium text-[10.63px] leading-[1.109] ">{description}</p>
  </div>
)

export const layoutQueryFragment = graphql`
  fragment Brands on WpPage_Page_FlexibleContent {
    ... on WpPage_Page_FlexibleContent_Brands {
      fieldGroupName
      ...coreCopyStackBrands
      ...sectionBrands
      defaultContent {
        heading
        text
        image {
          ...Image
        }
        stats {
          stat
          description
        }
        allBrands {
          ... on WpBrand {
            id
            importedBrandLogos {
              importedBrandLogos {
                ...Image
              }
            }
            brandPost {
              modal {
                companyName
                content
                heading
                images {
                  image {
                    ...Image
                  }
                }
                statsToShow
                brandStats {
                  users
                  usUsers
                  usSessions
                  usPageviews
                  ukUsers
                  ukSessions
                  ukPageviews
                  sessions
                  seoSessions
                  search
                  pageviews
                  male
                  fieldGroupName
                  female
                  caUsers
                  caSessions
                  caSessionsLabel
                  caUsersLabel
                  femaleLabel
                  maleLabel
                  pageviewsLabel
                  searchLabel
                  seoSessionsLabel
                  sessionsLabel
                  ukPageviewsLabel
                  ukSessionsLabel
                  ukUsersLabel
                  usPageviewsLabel
                  usSessionsLabel
                  usUsersLabel
                  usersLabel
                }
                stats {
                  stat
                  description
                }
                enquiries {
                  url
                  title
                }
                companyWebsite {
                  url
                  title
                }
              }
              brandPostPreview {
                logo {
                  ...Image
                }
              }
            }
            brandTaxonomies {
              nodes {
                name
              }
            }
          }
        }
      }
    }
  }
`
