import { graphql } from "gatsby"
import React from "react"
import {
  Section,
  SectionProps,
} from "~/components/FlexibleLayouts/elements/Section"
import {
  CoreCopyStack,
  CoreCopyStackProps,
} from "~/components/FlexibleLayouts/elements/Typography"
import TimelineSlider from "~/components/sliders/TimelineSlider"

export interface SliderHistoryProps {
  coreCopyStack: CoreCopyStackProps
  section: SectionProps
  sliders: any[]
}

export const SliderHistorySlider = ({
  coreCopyStack,
  section,
  sliders,
}: SliderHistoryProps) => (
  <Section {...section}>
    <div className="max-w-[714px]">
      <CoreCopyStack {...coreCopyStack} />
    </div>
    <div className="mt-12 md:mt-[88px]">
      <TimelineSlider sliders={sliders} />
    </div>
  </Section>
)

export const layoutQueryFragment = graphql`
  fragment SliderHistorySlider on WpPage_Page_FlexibleContent {
    ... on WpPage_Page_FlexibleContent_SliderHistorySlider {
      fieldGroupName
      ...coreCopyStackSliderHistorySlider
      ...sectionSliderHistorySlider
      sliders {
        year
        description
        logos {
          logo {
            ...Image
          }
        }
      }
    }
  }
`
